import { useState } from "react";
import { motion } from "framer-motion";

export const LinkedinAnimation = () => {
  const handleClick = () => {
    window.open("https://ge.linkedin.com/company/peakit0", "_blank");
  };
  const [animation, setAnimation] = useState(false);
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      viewBox="0 0 24 24"
      onMouseOver={() => setAnimation(true)}
      onMouseLeave={() => setAnimation(false)}
      style={{ cursor: "pointer" }}
      onClick={handleClick} // Call handleClick on click
    >
      <motion.path
        key={animation ? 1 : null}
        initial={{
          pathLength: animation ? 0 : 1,
        }}
        animate={{
          pathLength: 1,
          opacity: 1,
        }}
        transition={{
          duration: 2,
          delay: 0,
          repeat: Infinity,
        }}
        d="M16 8a6 6 0 016 6v7h-4v-7a2 2 0 00-2-2 2 2 0 00-2 2v7h-4v-7a6 6 0 016-6z"
      ></motion.path>
      <motion.path
        key={animation ? 2 : 3}
        initial={{
          pathLength: animation ? 0 : 1,
        }}
        animate={{
          pathLength: 1,
          opacity: 1,
        }}
        transition={{
          duration: 2,
          delay: 0,
          repeat: Infinity,
        }}
        d="M2 9H6V21H2z"
      ></motion.path>
      <motion.circle
        key={animation ? 4 : 5}
        initial={{
          pathLength: animation ? 0 : 1,
        }}
        animate={{
          pathLength: 1,
          opacity: 1,
        }}
        transition={{
          duration: 2,
          delay: 0,
          repeat: Infinity,
        }}
        cx="4"
        cy="4"
        r="2"
      ></motion.circle>
    </svg>
  );
};
