import { ABOUT_US_TEXTS } from "../../../constants";
import "../aboutUs.css";

export const MobileVersion = () => {
  return (
    <div className="about-container-mob non-mob-hidden">
      <div className="about-header-wrapper-mob">
        <div className="gradient-headline-wrapper-mob">
          <h3 className="about-headline-mob gradient-headline">
            {ABOUT_US_TEXTS.headlineGradient}
          </h3>
          <h3 className="about-headline-mob">{ABOUT_US_TEXTS.headline}</h3>
        </div>
        {ABOUT_US_TEXTS.description.map((val, index) => {
          return <p key={index}>{val}</p>;
        })}
      </div>
      <div className="about-content-wrapper-mob">
        {ABOUT_US_TEXTS.tabs.map((val, index) => {
          return (
            <div key={index} className="about-single-tab-mob">
              <p>{val.title}</p>
              <p>{val.num}</p>
            </div>
          );
        })}
      </div>
    </div>
  );
};
