import { useState } from "react";
import { motion } from "framer-motion";

export const MailAnimation = () => {
  const [animation, setAnimation] = useState(false);

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      className="feather feather-mail"
      viewBox="0 0 24 24"
      onMouseOver={() => setAnimation(true)}
      onMouseLeave={() => setAnimation(false)}
      style={{ cursor: "pointer" }}
    >
      <motion.path
        key={animation ? 1 : null}
        initial={{
          pathLength: animation ? 0 : 1,
        }}
        animate={{
          pathLength: 1,
          opacity: 1,
        }}
        transition={{
          duration: 2,
          delay: 0,
          repeat: Infinity,
        }}
        d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z"
      ></motion.path>
      <motion.path
        key={animation ? 2 : 3}
        initial={{
          pathLength: animation ? 0 : 1,
        }}
        animate={{
          pathLength: 1,
          opacity: 1,
        }}
        transition={{
          duration: 2,
          repeat: Infinity,
        }}
        d="M22 6L12 13 2 6"
      ></motion.path>
    </svg>
  );
};
