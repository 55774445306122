import { useState } from "react";
import { motion } from "framer-motion";

export const MenuIconAnimation = ({ animation }) => {
  return (
    <motion.svg
      initial={{ x: -5, y: 4 }}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      className="feather feather-menu"
      viewBox="0 0 24 24"
    >
      <motion.path
        key={animation ? 1 : null}
        initial={{
          pathLength: !animation ? 0 : 0.5,
          opacity: !animation ? 0 : 1,
        }}
        animate={{
          pathLength: !animation ? 0.5 : 0,
          opacity: !animation ? 1 : 0,
        }}
        transition={{
          duration: 1,
          delay: !animation ? 0.5 : 0,
          type: "spring",
        }}
        d="M3 12L21 12"
      ></motion.path>
      <motion.path
        key={animation ? 2 : 3}
        initial={{
          pathLength: !animation ? 0 : 1,
          opacity: !animation ? 0 : 1,
        }}
        animate={{
          pathLength: !animation ? 1 : 0,
          opacity: !animation ? 1 : 0,
        }}
        transition={{
          duration: 1,
          delay: !animation ? 0.5 : 0,
          type: "spring",
        }}
        d="M3 6L21 6"
      ></motion.path>
      <motion.path
        key={animation ? 4 : 5}
        initial={{
          pathLength: !animation ? 0 : 0.7,
          opacity: !animation ? 0 : 1,
        }}
        animate={{
          pathLength: !animation ? 0.7 : 0,
          opacity: !animation ? 1 : 0,
        }}
        transition={{
          duration: 1,
          delay: !animation ? 0.5 : 0,
          type: "spring",
        }}
        d="M3 18L21 18"
      ></motion.path>
      <motion.path
        key={animation ? 6 : 7}
        initial={{
          pathLength: animation ? 0 : 1,
          opacity: animation ? 0 : 1,
        }}
        animate={{
          pathLength: animation ? 1 : 0,
          opacity: animation ? 1 : 0,
        }}
        transition={{
          duration: 1,
          delay: animation ? 0.5 : 0,
          type: "spring",
        }}
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.529"
        d="M6.5 6.5L20.339 20.34M6.5 20.339L20.339 6.5"
      ></motion.path>
    </motion.svg>
  );
};
