import { useState } from "react";
import "../navigation.css";
import { NavArrowAnimation } from "../../../animation";
import { MENU_NAV } from "../../../constants";
import { motion } from "framer-motion";
// images

import arrow from "../../../assets/menu/arrow.webp";

export const DesktopVersion = ({
  setPageDisplayed,
  setMenu,
  setWarningModal,
}) => {
  const [animation, setAnimation] = useState("none");
  return (
    <div className="menu-container mob-hidden">
      <p
        onClick={() => {
          setPageDisplayed({ prev: "none", current: "none" });
          setMenu(false);
        }}
        className="menu-back-btn"
      >
        back to city
      </p>
      <div className="menu-nav-cont">
        {MENU_NAV.map((val, index) => {
          const { name, path } = val;
          return (
            <div
              onMouseOver={() => setAnimation(name)}
              onMouseLeave={() => setAnimation("none")}
              key={index}
              onClick={() => {
                if (path === "team") {
                  setWarningModal("open");
                } else {
                  setPageDisplayed({ prev: "none", current: path });
                  setMenu(false);
                }
              }}
              className="menu-single-nav-wrapper"
            >
              <div className="menu-single-nav-info">
                <motion.p
                  initial={{ x: -1000 }}
                  animate={{ x: 0 }}
                  transition={{ delay: 0.3 * index }}
                  key={index + 1}
                >
                  {name}
                </motion.p>
                <NavArrowAnimation animation={animation} name={name} />
              </div>
              <div>
                <div className="menu-singe-nav-line-blue"></div>
                <motion.div
                  key={index + 3}
                  initial={{ x: 3000 }}
                  animate={{ x: 0 }}
                  transition={{ delay: 0.1 * index }}
                  className="menu-singe-nav-line"
                ></motion.div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};
