import { useWindowSize } from "../../hooks";
import { DesktopVersion, MobileVersion } from "./atoms";

export const ChainsWeWorkOn = ({ pageDisplayed }) => {
  // detecting if user is using a mobile
  const { mobile, desktop } = useWindowSize();
  return (
    <>
      {desktop && <DesktopVersion pageDisplayed={pageDisplayed} />}
      {mobile && <MobileVersion pageDisplayed={pageDisplayed} />}
    </>
  );
};
