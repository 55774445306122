import "../../css/blockchain css/blockchainIndustries.css";
import { BLOCKCHAIN_INDUSTRIES_TEXTS, ROUTES } from "../../constants";
import { useEffect } from "react";

// images

const DesktopVersion = ({ pageDisplayed }) => {
  return (
    <div
      className={
        pageDisplayed.prev === ROUTES.chains &&
        pageDisplayed.current === ROUTES.blockchainIndustries
          ? "blockchainIndustries-container mob-hidden appear-up"
          : pageDisplayed.current === ROUTES.chains &&
            pageDisplayed.prev === ROUTES.blockchainIndustries
          ? "blockchainIndustries-container mob-hidden exit-down"
          : pageDisplayed.current === ROUTES.ar &&
            pageDisplayed.prev === ROUTES.blockchainIndustries
          ? "blockchainIndustries-container mob-hidden exit-up"
          : pageDisplayed.current === ROUTES.blockchainIndustries &&
            pageDisplayed.prev === ROUTES.ar
          ? "blockchainIndustries-container mob-hidden appear-down"
          : "blockchainIndustries-container mob-hidden hidden"
      }
    >
      <div className="blockchainIndustries-headline-wrapper">
        <h3>{BLOCKCHAIN_INDUSTRIES_TEXTS.headline}</h3>
        <h3>{BLOCKCHAIN_INDUSTRIES_TEXTS.headline1}</h3>
      </div>
      <div className="blockchainIndustries-content-wrapper">
        {BLOCKCHAIN_INDUSTRIES_TEXTS.tabs.map((val, index) => {
          return (
            <div key={index} className="blockchainIndustries-single-tab">
              <div>
                <img src={val.img} alt="icon" />
                <p>{val.headline}</p>
              </div>
              <ul>
                {val.tabs.map((val, index) => {
                  return <li key={index}>{val}</li>;
                })}
              </ul>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export const BlockchainIndustries = ({ pageDisplayed }) => {
  return (
    <>
      <DesktopVersion pageDisplayed={pageDisplayed} />
    </>
  );
};
