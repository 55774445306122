import { ROUTES, AUGMENTED_REALITY_TEXTS } from "../../../constants";
import "../augmentedReality.css";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination } from "swiper";

// images
import storiesDec from "../../../assets/success stories/storiesDec.webp";
import storiesDec2 from "../../../assets/success stories/storiesDec2.webp";
import Arrow from "../../../assets/common/ArrowRight.webp";
import ArrowLeft from "../../../assets/common/ArrowLeft.webp";

export const MobileVersion = ({
  setPagination,
  prevRef,
  nextRef,
  pageDisplayed,
}) => {
  return (
    <div
      className={
        pageDisplayed.prev === ROUTES.chains &&
        pageDisplayed.current === ROUTES.ar
          ? "AR-container-mob non-mob-hidden appear-up"
          : pageDisplayed.current === ROUTES.chains &&
            pageDisplayed.prev === ROUTES.ar
          ? "AR-container-mob non-mob-hidden exit-down"
          : pageDisplayed.current === ROUTES.bProjects &&
            pageDisplayed.prev === ROUTES.ar
          ? "AR-container-mob non-mob-hidden exit-up"
          : pageDisplayed.current === ROUTES.ar &&
            pageDisplayed.prev === ROUTES.bProjects
          ? "AR-container-mob non-mob-hidden appear-down"
          : "AR-container-mob non-mob-hidden hidden"
      }
    >
      <section className="AR-header-wrapper-mob">
        <div className="AR-gradient-headline-wrapper-mob">
          <h3 className="page-headline gradient-headline">
            {AUGMENTED_REALITY_TEXTS.headlineGradient}
          </h3>
          <h3 className="page-headline">{AUGMENTED_REALITY_TEXTS.headline}</h3>
        </div>
        <p>{AUGMENTED_REALITY_TEXTS.description}</p>
      </section>

      <div className="AR-content-mob">
        <Swiper
          modules={[Navigation, Pagination]}
          pagination={{
            clickable: true,
            type: "custom",
            renderCustom: (swiper, children) => setPagination(children),
          }}
          onInit={(swiper) => {
            swiper.params.navigation.prevEl = prevRef.current;
            swiper.params.navigation.nextEl = nextRef.current;
            swiper.navigation.init();
            swiper.navigation.update();
          }}
          slidesPerView={0.8}
          scrollbar={{ draggable: true }}
        >
          {AUGMENTED_REALITY_TEXTS.tabsMob.map((val, index) => {
            return (
              <SwiperSlide key={index}>
                <div className="AR-single-slide-mob">
                  <h5>{val.headline}</h5>
                  <p>{val.description}</p>
                </div>
                <div className="AR-single-slide-mob">
                  <h5>{val.headline2}</h5>
                  <p>{val.description2}</p>
                </div>
              </SwiperSlide>
            );
          })}
          <div className="blockchain-success-stories-arrows-wrapper">
            <img src={ArrowLeft} alt="arrow" ref={prevRef} />
            <img src={Arrow} alt="arrow" ref={nextRef} />
          </div>
        </Swiper>
      </div>
    </div>
  );
};
