import { ABOUT_US_TEXTS } from "../../../constants";
import { TypeAnimation } from "react-type-animation";
import "../aboutUs.css";
import { motion } from "framer-motion";

export const DesktopVersion = () => {
  return (
    <div className="about-container mob-hidden">
      <div className="about-header-wrapper">
        <motion.div
          initial={{ x: "-50vw", opacity: 0 }}
          animate={{ x: 0, opacity: 1 }}
          transition={{ duration: 1, type: "spring" }}
          className="gradient-headline-wrapper"
        >
          <h3 className="page-headline gradient-headline">
            {ABOUT_US_TEXTS.headlineGradient}
          </h3>
          <h3 className="page-headline">{ABOUT_US_TEXTS.headline}</h3>
        </motion.div>

        {ABOUT_US_TEXTS.description.map((v, i) => {
          return (
            <motion.p
              key={i}
              initial={{ x: i == 0 || i === 2 ? "50vw" : "-50vw", opacity: 0 }}
              animate={{ x: 0, opacity: 1 }}
              transition={{ duration: 1, type: "spring" }}
            >
              {v}
            </motion.p>
          );
        })}
      </div>
      <div className="about-content-wrapper">
        <div className="about-content-stacks">
          <TypeAnimation
            style={{
              whiteSpace: "pre-line",
              display: "block",
              width: "16vw",
              color: "#A8B7CE",
            }}
            sequence={[
              `${ABOUT_US_TEXTS.stacks[0]} \n ${ABOUT_US_TEXTS.stacks[1]} \n ${ABOUT_US_TEXTS.stacks[2]} \n ${ABOUT_US_TEXTS.stacks[3]} `,
              1000,
            ]}
            repeat={0}
            omitDeletionAnimation={true}
          />
        </div>
        <TypeAnimation
          style={{
            whiteSpace: "pre-line",
            display: "block",
            width: "16vw",
            color: "#A8B7CE",
          }}
          sequence={[
            `${ABOUT_US_TEXTS.tabs[0].title} ${ABOUT_US_TEXTS.tabs[0].num} \n ${ABOUT_US_TEXTS.tabs[1].title} ${ABOUT_US_TEXTS.tabs[1].num} \n ${ABOUT_US_TEXTS.tabs[2].title} ${ABOUT_US_TEXTS.tabs[2].num} \n ${ABOUT_US_TEXTS.tabs[3].title} ${ABOUT_US_TEXTS.tabs[3].num}`, // actual line-break inside string literal also gets animated in new line, but ensure there are no leading spaces
            1000,
          ]}
          repeat={0}
          omitDeletionAnimation={true}
        />
      </div>
    </div>
  );
};
