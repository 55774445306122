import { CHAINS_WE_WORK_ON_TEXTS, ROUTES } from "../../../constants";

// css
import "../chainsWeWorkOn.css";

// images
import metaverseCircles from "../../../assets/metaverse/metaverseCircles.webp";

export const DesktopVersion = ({ pageDisplayed }) => {
  return (
    <div
      className={
        pageDisplayed.prev === ROUTES.smartContractAudit2 &&
        pageDisplayed.current === ROUTES.chains
          ? "chains-we-work-container mob-hidden appear-up"
          : pageDisplayed.current === ROUTES.smartContractAudit2 &&
            pageDisplayed.prev === ROUTES.chains
          ? "chains-we-work-container mob-hidden exit-down"
          : pageDisplayed.current === ROUTES.blockchainIndustries &&
            pageDisplayed.prev === ROUTES.chains
          ? "chains-we-work-container mob-hidden exit-up"
          : pageDisplayed.current === ROUTES.chains &&
            pageDisplayed.prev === ROUTES.blockchainIndustries
          ? "chains-we-work-container mob-hidden appear-down"
          : "chains-we-work-container mob-hidden hidden"
      }
    >
      <section className="chains-header-wrapper">
        <div className="gradient-headline-wrapper">
          <h3 className="page-headline ">{CHAINS_WE_WORK_ON_TEXTS.headline}</h3>
          <h3 className="page-headline gradient-headline">
            {CHAINS_WE_WORK_ON_TEXTS.headlineGradient}
          </h3>
          <h3 className="page-headline">{CHAINS_WE_WORK_ON_TEXTS.headline1}</h3>
        </div>
        <p>{CHAINS_WE_WORK_ON_TEXTS.description}</p>
      </section>
      <div className="chains-we-work-content-wrapper">
        {CHAINS_WE_WORK_ON_TEXTS.tabs.map((val, index) => {
          return (
            <div className="chains-we-work-single-tab" key={index}>
              <h5>{val}</h5>
            </div>
          );
        })}
      </div>
      <img
        className="chains-we-work-circles"
        src={metaverseCircles}
        alt="circles"
      />
    </div>
  );
};
