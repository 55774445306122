import { CHAINS_WE_WORK_ON_TEXTS, ROUTES } from "../../../constants";

// css
import "../chainsWeWorkOn.css";

// images
import metaverseCircles from "../../../assets/metaverse/metaverseCircles.webp";
import chainsCube from "../../../assets/chains/chainsCube.webp";

export const MobileVersion = ({ pageDisplayed }) => {
  return (
    <div
      className={
        pageDisplayed.prev === ROUTES.smartContractAudit2 &&
        pageDisplayed.current === ROUTES.chains
          ? "AR-container-mob non-mob-hidden appear-up"
          : pageDisplayed.current === ROUTES.smartContractAudit2 &&
            pageDisplayed.prev === ROUTES.chains
          ? "AR-container-mob non-mob-hidden exit-down"
          : pageDisplayed.current === ROUTES.ar &&
            pageDisplayed.prev === ROUTES.chains
          ? "AR-container-mob non-mob-hidden exit-up"
          : pageDisplayed.current === ROUTES.chains &&
            pageDisplayed.prev === ROUTES.ar
          ? "AR-container-mob non-mob-hidden appear-down"
          : "AR-container-mob non-mob-hidden hidden"
      }
    >
      <section>
        <div className="chains-gradient-headline-wrapper-mob">
          <h3 className="page-headline ">{CHAINS_WE_WORK_ON_TEXTS.headline}</h3>
          <h3 className="page-headline gradient-headline">
            {CHAINS_WE_WORK_ON_TEXTS.headlineGradient}
          </h3>
          <h3 className="page-headline">{CHAINS_WE_WORK_ON_TEXTS.headline1}</h3>
        </div>
        <p className="chains-header-desc-mob">
          {CHAINS_WE_WORK_ON_TEXTS.description}
        </p>
      </section>
      <div className="chains-we-work-content-wrapper-mob">
        {CHAINS_WE_WORK_ON_TEXTS.tabs.map((val, index) => {
          if (val === "Wanchain") return;
          return (
            <div className="chains-we-work-single-tab-wrapper-mob" key={index}>
              <img src={chainsCube} alt="decor" />
              <div className="chains-we-work-single-tab-mob">
                <h5>{val}</h5>
              </div>
            </div>
          );
        })}
        <span className="chains-line-decor-mob"></span>
      </div>
      <img
        className="chains-we-work-circles-mob"
        src={metaverseCircles}
        alt="circles"
      />
    </div>
  );
};
