import "../css/landing.css";

import Background from "./background";
import BackgroundMob from "./backgroundMob";
import { useWindowSize } from "../hooks/useWindowSize";
// import mainPage from "../assets/common/HomePage.webp";

const DesktopVersion = () => {
  const { desktop } = useWindowSize();

  return (
    <div className="landing-container mob-hidden">
      {/* <img className="city" src={mainPage} alt="" /> */}
      {desktop && <Background />}

      {/* <Background /> */}
    </div>
  );
};
const MobileVersion = () => {
  const { mobile } = useWindowSize();

  return (
    <div className="landing-container-mob non-mob-hidden">
      {mobile && <BackgroundMob />}
      {/* <BackgroundMob /> */}
    </div>
  );
};

export const LandingPage = () => {
  return (
    <>
      <DesktopVersion />
      <MobileVersion />
    </>
  );
};
