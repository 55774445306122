import { useWindowSize } from "../../hooks";
import { DesktopVersion, MobileVersion } from "./atoms";

export const Services = ({
  setPageDisplayed,
  pageDisplayed,
  setNum,
  setWarningModal,
}) => {
  // detecting if user is using a mobile
  const { mobile, desktop } = useWindowSize();
  return (
    <>
      {desktop && (
        <DesktopVersion
          pageDisplayed={pageDisplayed}
          setPageDisplayed={setPageDisplayed}
          setNum={setNum}
          setWarningModal={setWarningModal}
        />
      )}
      {mobile && (
        <MobileVersion
          pageDisplayed={pageDisplayed}
          setPageDisplayed={setPageDisplayed}
          setNum={setNum}
        />
      )}
    </>
  );
};
