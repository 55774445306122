import { useEffect, useState } from "react";
import { paginationHandler } from "./handlers";
import { useWindowSize } from "./useWindowSize";

export const useScroll = (
  num,
  setPageDisplayed,
  pageDisplayed,
  setNum,
  projectToView,
  endY,
  startY
) => {
  const [blockEffect, setBlockEffect] = useState(false);
  // detecting if user is using a mobile

  const { mobile, desktop } = useWindowSize();

  // desktop scroll logic

  useEffect(() => {
    if (!blockEffect) {
      if (num >= 3) {
        paginationHandler(setPageDisplayed, pageDisplayed, num, mobile);
        setNum(0);
        setBlockEffect(true);
      } else if (num <= -3) {
        paginationHandler(setPageDisplayed, pageDisplayed, num, mobile);
        setNum(0);
        setBlockEffect(true);
      } else return;
    }

    const timeout = setTimeout(() => {
      setBlockEffect(false);
      setNum(0);
    }, 700);

    return () => clearTimeout(timeout);
  }, [num, blockEffect]);

  //  mobile scroll logic

  useEffect(() => {
    if (
      pageDisplayed.current === "about" ||
      pageDisplayed.current === "team" ||
      pageDisplayed.current === "contact" ||
      projectToView.status === "open"
    )
      return;
    if (endY > startY && endY - startY > 100) {
      if (pageDisplayed.current === "services") return;
      setNum(-3);
    }
    if (endY < startY && startY - endY > 100) {
      if (pageDisplayed.current === "blockchain projects") return;
      setNum(3);
    }
  }, [endY]);
};
