import { HEADER_TEXTS } from "../../../constants";
import "../header.css";
import { MenuIconAnimation } from "../../../animation";
import { debounce } from "lodash";

// images
import headerMenu from "../../../assets/header/headerMenu.webp";
import close from "../../../assets/header/close.webp";
import { Logo } from "../../../assets";

export const DesktopVersion = ({ setMenu, menu, setPageDisplayed }) => {
  const debouncedSetMenu = debounce(() => setMenu(!menu), 500);
  return (
    <div className="header-container mob-hidden">
      <Logo
        width="8vw"
        fill="url(#linear-gradient)"
        fill2="url(#linear-gradient-2)"
      />
      <div className="header-navigation">
        <p
          onClick={() => setPageDisplayed({ prev: "none", current: "contact" })}
        >
          {HEADER_TEXTS.contact}
        </p>
        <p onClick={debouncedSetMenu}>
          <MenuIconAnimation animation={menu} />
          {HEADER_TEXTS.menu}
        </p>
        {/* )} */}
      </div>
    </div>
  );
};
