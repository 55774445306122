import { SERVICES_TEXTS, ROUTES } from "../../../constants";
import "../services.css";

import scrollArrow from "../../../assets/common/scrollDown.webp";

export const MobileVersion = ({ setPageDisplayed, pageDisplayed, setNum }) => {
  return (
    <div
      className={
        pageDisplayed.current === ROUTES.services &&
        pageDisplayed.prev !== ROUTES.crypto
          ? "services-container-mob non-mob-hidden appear-up"
          : pageDisplayed.current === ROUTES.services &&
            pageDisplayed.prev === ROUTES.crypto
          ? "services-container-mob non-mob-hidden appear-down"
          : pageDisplayed.prev === ROUTES.services
          ? "services-container-mob non-mob-hidden exit-up"
          : "services-container-mob non-mob-hidden hidden"
      }
    >
      <h3 className="page-headline-mob">{SERVICES_TEXTS.headline}</h3>
      <div className="services-content-wrapper-mob">
        <div className="services-topics-mob">
          <p>{SERVICES_TEXTS.leftName}</p>
          {/* <p>{SERVICES_TEXTS.rightName}</p> */}
        </div>
        <div className="services-content-mob">
          {SERVICES_TEXTS.left.map((val, index) => {
            return (
              <p
                onClick={() => setPageDisplayed({ prev: "none", current: val })}
                key={index}
              >
                {val}
              </p>
            );
          })}
        </div>
        {/* <div className="services-content-right">
          <div className="services-content-right-tabs">
            {SERVICES_TEXTS.right.map((val, index) => {
              return (
                <p
                  onClick={() =>
                    setPageDisplayed((prev) => {
                      return { ...prev, current: val };
                    })
                  }
                  key={index}
                >
                  {val}
                </p>
              );
            })}
          </div>
        </div> */}
      </div>
      <div className="services-scroll-tip-wrapper-mob">
        <p>swipe down</p>
        <div className="services-scroll-tip-arrow-mob">
          <div className="services-scroll-tip-left-line-mob"></div>
          <img onClick={() => setNum(3)} src={scrollArrow} alt="arrow" />
          <div className="services-scroll-tip-right-line-mob"></div>
        </div>
      </div>
    </div>
  );
};
