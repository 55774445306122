import { PAGES_LIST } from "../constants/routes";

export const handleScroll = (
  event,
  setNum,
  num,
  pageDisplayed,
  isMobile,
  menu,
  projectToView
) => {
  // "isMobile" - to prevent scrolling when user is trying to zoom on mobile
  if (
    num >= 3 ||
    isMobile ||
    pageDisplayed.current === "team" ||
    pageDisplayed.current === "about" ||
    pageDisplayed.current === "contact" ||
    num <= -3 ||
    menu ||
    projectToView.status === "open"
  )
    return;
  if (event.deltaY > 0) {
    if (pageDisplayed.current === "blockchain projects") return;
    setNum((prev) => prev + 1);
  } else if (event.deltaY < 0) {
    if (
      pageDisplayed.current === "none" ||
      pageDisplayed.current === "services"
    )
      return;
    setNum((prev) => prev - 1);
  }
};

export const paginationHandler = (setState, state, num, isMobile) => {
  if (num > 0) {
    setState((val) => {

      let currentPageIndex = PAGES_LIST.findIndex((v) => v === val.current);
      let prevPage = "none";

      // mobile version is skipping blockchain industries page
      if (isMobile && val.current === "chains we work on") {
        prevPage = "chains we work on";
        currentPageIndex = 9;
      } else if (currentPageIndex === -1) {
        currentPageIndex = 0; // Set the default index to 0 if val.current is not found in PAGES_LIST
      } else if (currentPageIndex !== -1) {
        prevPage = PAGES_LIST[currentPageIndex];
        currentPageIndex++;
      }
      return { prev: prevPage, current: PAGES_LIST[currentPageIndex] };
    });
  }
  if (num < 0) {
    setState((val) => {

      let currentPageIndex = PAGES_LIST.findIndex((v) => v === val.current);
      let prevPage = "none";

      // mobile version is skipping blockchain industries page
      if (isMobile && val.current === "Augmented Reality Development") {
        prevPage = "Augmented Reality Development";
        currentPageIndex = 7;
      } else if (currentPageIndex !== 0) {
        prevPage = PAGES_LIST[currentPageIndex];
        currentPageIndex--;
      }
      return { prev: prevPage, current: PAGES_LIST[currentPageIndex] };
    });
  }
};
