import { useState } from "react";
import { motion } from "framer-motion";

export const TwitterAnimation = () => {
  const [animation, setAnimation] = useState(false);

  const handleClick = () => {
    window.open("https://twitter.com/PeakIT_00", "_blank");
  };

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlSpace="preserve"
      style={{
        enableBackground: "new 0 0 1668.56 1221.19",
        cursor: "pointer",
      }}
      viewBox="0 0 1668.56 1221.19"
      width="55px"
      onMouseOver={() => setAnimation(true)}
      onMouseLeave={() => setAnimation(false)}
      onClick={handleClick} // Call handleClick on click
    >
      <circle
        cx={834.28}
        cy={610.6}
        r={481.33}
        style={{
          stroke: "#fff",
          strokeMiterlimit: 10,
        }}
      />
      <motion.path
        key={animation ? 1 : null}
        initial={{
          pathLength: 0, // Start with pathLength of 0
          opacity: animation ? 0 : 1, // Start with opacity of 0
          scale: animation ? 0 : 1, // Start with opacity of 0
          x: 52,
          y: -25,
        }}
        animate={{
          pathLength: animation ? 1 : 0, // Animate pathLength to 1 when animation is true
          opacity: 1, // Animate opacity to 1
          scale: 1,
        }}
        transition={{
          duration: 1,
          delay: 0,
        }}
        d="m485.39 356.79 230.07 307.62-231.52 250.11h52.11l202.7-218.98 163.77 218.98h177.32L836.82 589.6l215.5-232.81h-52.11L813.54 558.46 662.71 356.79H485.39zm76.63 38.38h81.46l359.72 480.97h-81.46L562.02 395.17z"
        style={{
          fill: "#fff",
        }}
        transform="translate(52.39 -25.059)"
      />
    </svg>
  );
};
