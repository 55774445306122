import "../../css/blockchain css/defi.css";
import { DEFI_TEXTS, ROUTES } from "../../constants";

// images

import defiCircles from "../../assets/defi/defiCircles.webp";
import defiCubes from "../../assets/defi/defiCubes.webp";
import defiCubesMob from "../../assets/defi/defiCubes.webp";
import defiNumCircle from "../../assets/defi/defiNumCircle.webp";

const DesktopVersion = ({ pageDisplayed }) => {
  return (
    <div
      className={
        pageDisplayed.prev === ROUTES.metaverse &&
        pageDisplayed.current === ROUTES.defi
          ? "defi-container mob-hidden appear-up"
          : pageDisplayed.current === ROUTES.metaverse &&
            pageDisplayed.prev === ROUTES.defi
          ? "defi-container mob-hidden exit-down"
          : pageDisplayed.current === ROUTES.smartContractAudit &&
            pageDisplayed.prev === ROUTES.defi
          ? "defi-container mob-hidden exit-up"
          : pageDisplayed.current === ROUTES.defi &&
            pageDisplayed.prev === ROUTES.smartContractAudit
          ? "defi-container mob-hidden appear-down"
          : pageDisplayed.current === ROUTES.defi &&
            pageDisplayed.prev === "none"
          ? "defi-container mob-hidden"
          : "defi-container mob-hidden hidden"
      }
    >
      <div className="defi-headline-wrapper">
        <div>
          <h3>{DEFI_TEXTS.headline}</h3>
          <h3>{DEFI_TEXTS.headline1}</h3>
        </div>
        <img src={defiCircles} alt="circles" className="defi-circles" />
        <img src={defiCubes} alt="cubes" />
      </div>
      <div className="defi-content-wrapper">
        {DEFI_TEXTS.tabs.map((val, index) => {
          const num = index + 1;
          return (
            <div key={index}>
              <div className="defi-content-num">
                <img src={defiNumCircle} alt="circle" />
                <p>{num}</p>
              </div>
              <p>{val}</p>
            </div>
          );
        })}
      </div>
    </div>
  );
};

const MobileVersion = ({ pageDisplayed }) => {
  return (
    <div
      className={
        pageDisplayed.prev === ROUTES.metaverse &&
        pageDisplayed.current === ROUTES.defi
          ? "defi-container-mob non-mob-hidden appear-up"
          : pageDisplayed.current === ROUTES.metaverse &&
            pageDisplayed.prev === ROUTES.defi
          ? "defi-container-mob non-mob-hidden exit-down"
          : pageDisplayed.current === ROUTES.smartContractAudit &&
            pageDisplayed.prev === ROUTES.defi
          ? "defi-container-mob non-mob-hidden exit-up"
          : pageDisplayed.current === ROUTES.defi &&
            pageDisplayed.prev === ROUTES.smartContractAudit
          ? "defi-container-mob non-mob-hidden appear-down"
          : pageDisplayed.current === ROUTES.defi &&
            pageDisplayed.prev === "none"
          ? "defi-container-mob non-mob-hidden"
          : "defi-container-mob non-mob-hidden hidden"
      }
    >
      <div className="defi-headline-wrapper-mob">
        <div>
          <h3>{DEFI_TEXTS.headline}</h3>
          <h3>{DEFI_TEXTS.headline1}</h3>
        </div>
        <img src={defiCircles} alt="circles" className="defi-circles-mob" />
        <img src={defiCubesMob} alt="cubes" />
      </div>
      <div className="defi-content-wrapper-mob">
        {DEFI_TEXTS.tabs.map((val, index) => {
          const num = index + 1;
          return (
            <div key={index}>
              <div className="defi-content-num-mob">
                <img src={defiNumCircle} alt="circle" />
                <p>{num}</p>
              </div>
              <p>{val}</p>
            </div>
          );
        })}
      </div>
    </div>
  );
};
export const DeFi = ({ pageDisplayed }) => {
  return (
    <>
      <DesktopVersion pageDisplayed={pageDisplayed} />
      <MobileVersion pageDisplayed={pageDisplayed} />
    </>
  );
};
