import { BLOCKCHAIN_PROJECTS_TEXTS, ROUTES } from "../../../constants";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Parallax } from "swiper";
// css
import "../projects.css";

// images
import Arrow from "../../../assets/common/ArrowRight.webp";
import ArrowLeft from "../../../assets/common/ArrowLeft.webp";

export const MobileVersion = ({
  setPagination,
  prevRef,
  nextRef,
  pageDisplayed,
  setProjectToView,
}) => {
  return (
    <div
      className={
        pageDisplayed.prev === ROUTES.ar &&
        pageDisplayed.current === ROUTES.bProjects
          ? "blockchain-projects-container-mob non-mob-hidden appear-up"
          : pageDisplayed.current === ROUTES.ar &&
            pageDisplayed.prev === ROUTES.bProjects
          ? "blockchain-projects-container-mob non-mob-hidden exit-down"
          : "blockchain-projects-container-mob non-mob-hidden hidden"
      }
    >
      <div className="blockchain-projects-header-mob">
        <h3>{BLOCKCHAIN_PROJECTS_TEXTS.headline}</h3>
      </div>

      <div className="blockchain-projects-content-wrapper-mob">
        <Swiper
          modules={[Navigation, Pagination, Parallax]}
          pagination={{
            clickable: true,
            type: "custom",
            renderCustom: (swiper, children) => setPagination(children),
          }}
          onInit={(swiper) => {
            swiper.params.navigation.prevEl = prevRef.current;
            swiper.params.navigation.nextEl = nextRef.current;
            swiper.navigation.init();
            swiper.navigation.update();
          }}
          spaceBetween={100}
          slidesPerView={1}
          scrollbar={{ draggable: true }}
          observer={true}
          observeParents={true}
          parallax={true}
        >
          {BLOCKCHAIN_PROJECTS_TEXTS.tabs.map((val, index) => {
            return (
              <SwiperSlide key={index}>
                <div
                  onClick={() => {
                    setProjectToView({ status: "open", project: val });
                  }}
                  className="blockchain-projects-single-slide-mob"
                >
                  <img src={val.img} alt="slide img" />
                  <div className="blockchain-projects-single-slide-desc-mob">
                    <p>{val.fullTitle}</p>
                  </div>
                </div>
              </SwiperSlide>
            );
          })}
          <div className="blockchain-projects-arrow-wrapper-mob">
            <img
              ref={prevRef}
              className="blockchain-projects-arrow-mob"
              src={ArrowLeft}
              alt="left arrow"
            />
            <img
              ref={nextRef}
              className="blockchain-projects-arrow-mob"
              src={Arrow}
              alt="right arrow"
            />
          </div>
        </Swiper>
      </div>
    </div>
  );
};
