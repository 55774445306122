import { useEffect, useState } from "react";
import {
  handleScroll,
  paginationHandler,
  useScroll,
  useWindowSize,
} from "./hooks";
import {
  LandingPage,
  Header,
  Footer,
  CurrentPage,
  WarningModal,
} from "./components";
import { Navigation } from "./pages";
import { ProjectViewDesktop } from "./pages/projects page/atoms/projectViewDesktop";

function App() {
  const [menu, setMenu] = useState(false);
  const [pageDisplayed, setPageDisplayed] = useState({
    prev: "none",
    current: "none",
  });
  const [num, setNum] = useState(0);

  // project state
  const [projectToView, setProjectToView] = useState({
    project: {},
    status: "none",
  });

  // warning modal state
  const [warningModal, setWarningModal] = useState("none");

  // detecting if user is using a mobile

  const { mobile, desktop } = useWindowSize();

  // mobile scroll
  const [startY, setStartY] = useState(0);
  const [endY, setEndY] = useState(0);

  // handles scroll
  useScroll(
    num,
    setPageDisplayed,
    pageDisplayed,
    setNum,
    projectToView,
    endY,
    startY
  );

  return (
    <div
      className="main-container"
      onWheel={(e) =>
        handleScroll(e, setNum, num, pageDisplayed, mobile, menu, projectToView)
      }
      onTouchStart={(e) => {
        setStartY(e.changedTouches[0].clientY);
      }}
      onTouchEnd={(e) => setEndY(e.changedTouches[0].clientY)}
    >
      {warningModal !== "none" && (
        <WarningModal
          setWarningModal={setWarningModal}
          warningModal={warningModal}
        />
      )}
      {projectToView.status === "open" && (
        <ProjectViewDesktop
          setProjectToView={setProjectToView}
          projectToView={projectToView}
        />
      )}
      <Header
        setMenu={setMenu}
        menu={menu}
        setPageDisplayed={setPageDisplayed}
      />
      <LandingPage />
      <Footer />
      {menu && (
        <Navigation
          setWarningModal={setWarningModal}
          setMenu={setMenu}
          setPageDisplayed={setPageDisplayed}
        />
      )}
      <CurrentPage
        setNum={setNum}
        pageDisplayed={pageDisplayed}
        setPageDisplayed={setPageDisplayed}
        projectToView={projectToView}
        setProjectToView={setProjectToView}
        setWarningModal={setWarningModal}
      />
    </div>
  );
}

export default App;
