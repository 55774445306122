import { ROUTES, AUGMENTED_REALITY_TEXTS } from "../../../constants";
import "../augmentedReality.css";

export const DesktopVersion = ({ pageDisplayed }) => {
  return (
    <div
      className={
        pageDisplayed.prev === ROUTES.blockchainIndustries &&
        pageDisplayed.current === ROUTES.ar
          ? "AR-container mob-hidden appear-up"
          : pageDisplayed.current === ROUTES.blockchainIndustries &&
            pageDisplayed.prev === ROUTES.ar
          ? "AR-container mob-hidden exit-down"
          : pageDisplayed.current === ROUTES.bProjects &&
            pageDisplayed.prev === ROUTES.ar
          ? "AR-container mob-hidden exit-up"
          : pageDisplayed.current === ROUTES.ar &&
            pageDisplayed.prev === ROUTES.bProjects
          ? "AR-container mob-hidden appear-down"
          : pageDisplayed.current === ROUTES.ar && pageDisplayed.prev === "none"
          ? "AR-container mob-hidden"
          : "AR-container mob-hidden hidden"
      }
    >
      <section className="AR-header-wrapper">
        <div className="gradient-headline-wrapper">
          <h3 className="page-headline gradient-headline">
            {AUGMENTED_REALITY_TEXTS.headlineGradient}
          </h3>
          <h3 className="page-headline">{AUGMENTED_REALITY_TEXTS.headline}</h3>
        </div>
        <p>{AUGMENTED_REALITY_TEXTS.description}</p>
      </section>
      <div className="AR-content-wrapper">
        {AUGMENTED_REALITY_TEXTS.tabs.map((val, index) => {
          return (
            <div className="AR-single-tab" key={index}>
              <h5>{val.headline}</h5>
              <p>{val.description}</p>
            </div>
          );
        })}
      </div>
    </div>
  );
};
