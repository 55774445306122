import "./warningModal.css";
import { motion } from "framer-motion";
// images
import close from "../../assets/common/close.webp";
import { useEffect } from "react";

export const WarningModal = ({ setWarningModal, warningModal }) => {
  // modal will automatically close in 2s
  useEffect(() => {
    if (warningModal !== "open") return;
    if (warningModal === "close") {
      setTimeout(() => {
        setWarningModal("none");
      }, 2000);
    }
    setTimeout(() => {
      setWarningModal("close");
    }, 3000);
  }, [warningModal]);

  return (
    <motion.div
      initial={{ y: warningModal === "open" ? -150 : 100 }}
      animate={{ y: warningModal === "open" ? 100 : -150 }}
      transition={{ type: "tween" }}
      className="wm-container"
    >
      <img
        onClick={() => setWarningModal("close")}
        className="wm-close-btn"
        src={close}
        alt="close"
      />
      <p>Page will be added soon!</p>
    </motion.div>
  );
};
