import { useRef, useState } from "react";
import { useWindowSize } from "../../hooks";
import { DesktopVersion, MobileVersion } from "./atoms";

export const BlockchainProjects = ({
  pageDisplayed,
  projectToView,
  setProjectToView,
}) => {
  const prevRef = useRef(null);
  const nextRef = useRef(null);
  const [pagination, setPagination] = useState(1);

  // detecting if user is using a mobile
  const { mobile, desktop } = useWindowSize();
  return (
    <>
      {desktop && (
        <DesktopVersion
          prevRef={prevRef}
          nextRef={nextRef}
          setPagination={setPagination}
          pageDisplayed={pageDisplayed}
          projectToView={projectToView}
          setProjectToView={setProjectToView}
        />
      )}
      {mobile && (
        <MobileVersion
          prevRef={prevRef}
          nextRef={nextRef}
          setPagination={setPagination}
          pageDisplayed={pageDisplayed}
          setProjectToView={setProjectToView}
        />
      )}
    </>
  );
};
