import { useEffect, useState } from "react";
import "../../css/blockchain css/crypto.css";
import { CRYPTO_TEXTS, ROUTES } from "../../constants";

// images

import cryptoDec1 from "../../assets/crypto/cryptoDec1.webp";
import cryptoDec2 from "../../assets/crypto/cryptoDec2.webp";
import cryptoDec3 from "../../assets/crypto/cryptoDec3.webp";
import cryptoDec4 from "../../assets/crypto/cryptoDec4.webp";
import cryptoDec5 from "../../assets/crypto/cryptoDec5.webp";
import cryptoDec6 from "../../assets/crypto/cryptoDec6.webp";
import cryptoDec7 from "../../assets/crypto/cryptoDec7.webp";

const DesktopVersion = ({ pageDisplayed, tabDisplayed, setTabDisplayed }) => {
  return (
    <div
      className={
        pageDisplayed.prev === ROUTES.crypto &&
        pageDisplayed.current === ROUTES.blockchain
          ? "crypto-container mob-hidden exit-up"
          : pageDisplayed.current === ROUTES.services &&
            pageDisplayed.prev === ROUTES.crypto
          ? "crypto-container mob-hidden exit-down"
          : pageDisplayed.current === ROUTES.crypto &&
            pageDisplayed.prev === ROUTES.blockchain
          ? "crypto-container mob-hidden appear-down"
          : pageDisplayed.current === ROUTES.crypto &&
            pageDisplayed.prev === ROUTES.services
          ? "crypto-container mob-hidden appear-up"
          : pageDisplayed.current === ROUTES.crypto &&
            pageDisplayed.prev === "none"
          ? "crypto-container mob-hidden"
          : "crypto-container mob-hidden hidden"
      }
    >
      <div className="crypto-headline-wrapper">
        <div className="gradient-headline-wrapper">
          <h3 className="page-headline gradient-headline">
            {CRYPTO_TEXTS.headlineGradient}
          </h3>
          <h3 className="page-headline">{CRYPTO_TEXTS.headline}</h3>
        </div>
        <p
          className={
            tabDisplayed === "solidity"
              ? "crypto-desc opacity-one"
              : "crypto-desc opacity-zero"
          }
        >
          {CRYPTO_TEXTS.descriptionSolidity}
        </p>
        <p
          className={
            tabDisplayed === "web3"
              ? "crypto-desc opacity-one"
              : "crypto-desc opacity-zero"
          }
        >
          {CRYPTO_TEXTS.descriptionWeb3}
        </p>
        <p
          className={
            tabDisplayed === "smart"
              ? "crypto-desc opacity-one"
              : "crypto-desc opacity-zero"
          }
        >
          {CRYPTO_TEXTS.descriptionSmart}
        </p>
        <p
          className={
            tabDisplayed === "enterprise"
              ? "crypto-desc opacity-one"
              : "crypto-desc opacity-zero"
          }
        >
          {CRYPTO_TEXTS.descriptionEnterprise}
        </p>
      </div>
      <div className="crypto-content-wrapper">
        <p
          onMouseEnter={() => setTabDisplayed("web3")}
          className={
            pageDisplayed.current === ROUTES.crypto
              ? "crypto-tab-top opacity-zero animated-crypto-tab-top"
              : "crypto-tab-top"
          }
        >
          {CRYPTO_TEXTS.tabTop}
        </p>
        <p
          onMouseEnter={() => setTabDisplayed("solidity")}
          className={
            pageDisplayed.current === ROUTES.crypto
              ? "crypto-tab-left opacity-zero animated-crypto-tab-left"
              : "crypto-tab-left"
          }
        >
          {CRYPTO_TEXTS.tabLeft}
        </p>
        <p
          onMouseEnter={() => setTabDisplayed("smart")}
          className={
            pageDisplayed.current === ROUTES.crypto
              ? "crypto-tab-right opacity-zero animated-crypto-tab-right"
              : "crypto-tab-right"
          }
        >
          {CRYPTO_TEXTS.tabRight}
        </p>
        <p
          onMouseEnter={() => setTabDisplayed("enterprise")}
          className={
            pageDisplayed.current === ROUTES.crypto
              ? "crypto-tab-bot opacity-zero animated-crypto-tab-bot"
              : "crypto-tab-bot"
          }
        >
          {CRYPTO_TEXTS.tabBot}
        </p>
        <div className="crypto-decor-wrapper">
          <img
            className={
              pageDisplayed.current === ROUTES.crypto
                ? "crypto-decor-1 opacity-zero animated-crypto-decor-1"
                : "crypto-decor-1"
            }
            src={cryptoDec1}
            alt="crypto decor"
          />
          <img
            className={
              pageDisplayed.current === ROUTES.crypto
                ? "crypto-decor-2 opacity-zero animated-crypto-decor-2"
                : "crypto-decor-2"
            }
            src={cryptoDec2}
            alt="crypto decor"
          />
          <img
            className={
              pageDisplayed.current === ROUTES.crypto
                ? "crypto-decor-3 opacity-zero animated-crypto-decor-3"
                : "crypto-decor-3"
            }
            src={cryptoDec3}
            alt="crypto decor"
          />
          <img
            className={
              pageDisplayed.current === ROUTES.crypto
                ? "crypto-decor-4 opacity-zero animated-crypto-decor-4"
                : "crypto-decor-4"
            }
            src={cryptoDec4}
            alt="crypto decor"
          />
          <img
            className={
              pageDisplayed.current === ROUTES.crypto
                ? "crypto-decor-5 opacity-zero animated-crypto-decor-5"
                : "crypto-decor-5"
            }
            src={cryptoDec5}
            alt="crypto decor"
          />
          <img
            className={
              pageDisplayed.current === ROUTES.crypto
                ? "crypto-decor-6 opacity-zero animated-crypto-decor-6"
                : "crypto-decor-6"
            }
            src={cryptoDec6}
            alt="crypto decor"
          />
          <img
            className={
              pageDisplayed.current === ROUTES.crypto
                ? "crypto-decor-7-top opacity-zero animated-crypto-decor-7-top"
                : "crypto-decor-7-top"
            }
            src={cryptoDec7}
            alt="crypto decor"
          />
          {tabDisplayed === "web3" || tabDisplayed === "none" ? null : (
            <div className="crypto-white-ball-top"></div>
          )}
          {tabDisplayed === "enterprise" || tabDisplayed === "none" ? null : (
            <div className="crypto-white-ball-bot"></div>
          )}
          {tabDisplayed === "solidity" || tabDisplayed === "none" ? null : (
            <div className="crypto-white-ball-left"></div>
          )}
          {tabDisplayed === "smart" || tabDisplayed === "none" ? null : (
            <div className="crypto-white-ball-right"></div>
          )}
          <img
            className={
              pageDisplayed.current === ROUTES.crypto
                ? "crypto-decor-7-left opacity-zero animated-crypto-decor-7-left"
                : "crypto-decor-7-left"
            }
            src={cryptoDec7}
            alt="crypto decor"
          />
          <img
            className={
              pageDisplayed.current === ROUTES.crypto
                ? "crypto-decor-7-right opacity-zero animated-crypto-decor-7-right"
                : "crypto-decor-7-right"
            }
            src={cryptoDec7}
            alt="crypto decor"
          />
          <img
            className={
              pageDisplayed.current === ROUTES.crypto
                ? "crypto-decor-7-bot opacity-zero animated-crypto-decor-7-bot"
                : "crypto-decor-7-bot"
            }
            src={cryptoDec7}
            alt="crypto decor"
          />
        </div>
      </div>
    </div>
  );
};

const MobileVersion = ({ pageDisplayed, tabDisplayed, setTabDisplayed }) => {
  return (
    <div
      className={
        pageDisplayed.prev === ROUTES.crypto &&
        pageDisplayed.current === ROUTES.blockchain
          ? "crypto-container-mob non-mob-hidden exit-up"
          : pageDisplayed.current === ROUTES.services &&
            pageDisplayed.prev === ROUTES.crypto
          ? "crypto-container-mob non-mob-hidden exit-down"
          : pageDisplayed.current === ROUTES.crypto &&
            pageDisplayed.prev === ROUTES.blockchain
          ? "crypto-container-mob non-mob-hidden appear-down"
          : pageDisplayed.current === ROUTES.crypto &&
            pageDisplayed.prev === ROUTES.services
          ? "crypto-container-mob non-mob-hidden appear-up"
          : pageDisplayed.current === ROUTES.crypto &&
            pageDisplayed.prev === "none"
          ? "crypto-container-mob non-mob-hidden"
          : "crypto-container-mob non-mob-hidden hidden"
      }
    >
      <div className="crypto-headline-wrapper-mob">
        <div className="gradient-headline-wrapper">
          <h3 className="crypto-page-headline-mob gradient-headline">
            {CRYPTO_TEXTS.headlineGradient}
          </h3>
          <h3 className="crypto-page-headline-mob">{CRYPTO_TEXTS.headline}</h3>
        </div>
        <p
          className={
            tabDisplayed === "solidity"
              ? "crypto-desc-mob opacity-one"
              : "crypto-desc-mob opacity-zero"
          }
        >
          {CRYPTO_TEXTS.descriptionSolidity}
        </p>
        <p
          className={
            tabDisplayed === "web3"
              ? "crypto-desc-mob opacity-one"
              : "crypto-desc-mob opacity-zero"
          }
        >
          {CRYPTO_TEXTS.descriptionWeb3}
        </p>
        <p
          className={
            tabDisplayed === "smart"
              ? "crypto-desc-mob opacity-one"
              : "crypto-desc-mob opacity-zero"
          }
        >
          {CRYPTO_TEXTS.descriptionSmart}
        </p>
        <p
          className={
            tabDisplayed === "enterprise"
              ? "crypto-desc-mob opacity-one"
              : "crypto-desc-mob opacity-zero"
          }
        >
          {CRYPTO_TEXTS.descriptionEnterprise}
        </p>
      </div>
      <div className="crypto-content-wrapper-mob">
        <p
          onMouseEnter={() => setTabDisplayed("web3")}
          className={
            pageDisplayed.current === ROUTES.crypto
              ? "crypto-tab-top-mob opacity-zero animated-crypto-tab-top-mob"
              : "crypto-tab-top-mob"
          }
        >
          {CRYPTO_TEXTS.tabTop}
        </p>
        <p
          onMouseEnter={() => setTabDisplayed("solidity")}
          className={
            pageDisplayed.current === ROUTES.crypto
              ? "crypto-tab-left-mob opacity-zero animated-crypto-tab-left-mob"
              : "crypto-tab-left-mob"
          }
        >
          {CRYPTO_TEXTS.tabLeft}
        </p>
        <p
          onMouseEnter={() => setTabDisplayed("smart")}
          className={
            pageDisplayed.current === ROUTES.crypto
              ? "crypto-tab-right-mob opacity-zero animated-crypto-tab-right-mob"
              : "crypto-tab-right-mob"
          }
        >
          {CRYPTO_TEXTS.tabRight}
        </p>
        <p
          onMouseEnter={() => setTabDisplayed("enterprise")}
          className={
            pageDisplayed.current === ROUTES.crypto
              ? "crypto-tab-bot-mob opacity-zero animated-crypto-tab-bot-mob"
              : "crypto-tab-bot-mob"
          }
        >
          {CRYPTO_TEXTS.tabBot}
        </p>
        <div className="crypto-decor-wrapper-mob">
          <img
            className={
              pageDisplayed.current === ROUTES.crypto
                ? "crypto-decor-1-mob opacity-zero animated-crypto-decor-1"
                : "crypto-decor-1-mob"
            }
            src={cryptoDec1}
            alt="crypto decor"
          />
          <img
            className={
              pageDisplayed.current === ROUTES.crypto
                ? "crypto-decor-2-mob opacity-zero animated-crypto-decor-2"
                : "crypto-decor-2-mob"
            }
            src={cryptoDec2}
            alt="crypto decor"
          />
          <img
            className={
              pageDisplayed.current === ROUTES.crypto
                ? "crypto-decor-3-mob opacity-zero animated-crypto-decor-3"
                : "crypto-decor-3-mob"
            }
            src={cryptoDec3}
            alt="crypto decor"
          />
          <img
            className={
              pageDisplayed.current === ROUTES.crypto
                ? "crypto-decor-4-mob opacity-zero animated-crypto-decor-4"
                : "crypto-decor-4-mob"
            }
            src={cryptoDec4}
            alt="crypto decor"
          />
          <img
            className={
              pageDisplayed.current === ROUTES.crypto
                ? "crypto-decor-5-mob opacity-zero animated-crypto-decor-5"
                : "crypto-decor-5-mob"
            }
            src={cryptoDec5}
            alt="crypto decor"
          />
          <img
            className={
              pageDisplayed.current === ROUTES.crypto
                ? "crypto-decor-6-mob opacity-zero animated-crypto-decor-6"
                : "crypto-decor-6-mob"
            }
            src={cryptoDec6}
            alt="crypto decor"
          />
          <img
            className={
              pageDisplayed.current === ROUTES.crypto
                ? "crypto-decor-7-top-mob opacity-zero animated-crypto-decor-7-top-mob"
                : "crypto-decor-7-top-mob"
            }
            src={cryptoDec7}
            alt="crypto decor"
          />
          {tabDisplayed === "web3" || tabDisplayed === "none" ? null : (
            <div className="crypto-white-ball-top-mob"></div>
          )}
          {tabDisplayed === "enterprise" || tabDisplayed === "none" ? null : (
            <div className="crypto-white-ball-bot-mob"></div>
          )}
          {tabDisplayed === "solidity" || tabDisplayed === "none" ? null : (
            <div className="crypto-white-ball-left-mob"></div>
          )}
          {tabDisplayed === "smart" || tabDisplayed === "none" ? null : (
            <div className="crypto-white-ball-right-mob"></div>
          )}
          <img
            className={
              pageDisplayed.current === ROUTES.crypto
                ? "crypto-decor-7-left-mob opacity-zero animated-crypto-decor-7-left-mob"
                : "crypto-decor-7-left-mob"
            }
            src={cryptoDec7}
            alt="crypto decor"
          />
          <img
            className={
              pageDisplayed.current === ROUTES.crypto
                ? "crypto-decor-7-right-mob opacity-zero animated-crypto-decor-7-right-mob"
                : "crypto-decor-7-right-mob"
            }
            src={cryptoDec7}
            alt="crypto decor"
          />
          <img
            className={
              pageDisplayed.current === ROUTES.crypto
                ? "crypto-decor-7-bot-mob opacity-zero animated-crypto-decor-7-bot-mob"
                : "crypto-decor-7-bot-mob"
            }
            src={cryptoDec7}
            alt="crypto decor"
          />
        </div>
      </div>
    </div>
  );
};

export const Crypto = ({ pageDisplayed }) => {
  const [tabDisplayed, setTabDisplayed] = useState("none");

  // takes care of white balls animation
  useEffect(() => {
    if (pageDisplayed.current !== ROUTES.crypto && tabDisplayed === "none")
      return;
    if (pageDisplayed.current !== ROUTES.crypto) {
      const timeout = setTimeout(() => {
        setTabDisplayed("none");
      }, 1000);

      return () => clearTimeout(timeout);
    }
    const timeout = setTimeout(() => {
      setTabDisplayed("solidity");
    }, 3000);

    return () => clearTimeout(timeout);
  }, [pageDisplayed]);

  return (
    <>
      <DesktopVersion
        pageDisplayed={pageDisplayed}
        tabDisplayed={tabDisplayed}
        setTabDisplayed={setTabDisplayed}
      />
      <MobileVersion
        pageDisplayed={pageDisplayed}
        tabDisplayed={tabDisplayed}
        setTabDisplayed={setTabDisplayed}
      />
    </>
  );
};
