import { ROUTES } from "../constants";
import {
  Blockchain,
  BlockchainIndustries,
  Crypto,
  DeFi,
  Metaverse,
  SmartContractAudit,
  Consulting,
} from "./temporary folder";
import {
  AboutUs,
  AugmentedReality,
  Services,
  SuccessStories,
  ChainsWeWorkOn,
  ContactUs,
  BlockchainProjects,
} from "../pages";
import { Team } from "./team";

import scrollDown from "../assets/common/scrollDown.webp";
import close from "../assets/common/close.webp";
import { ArrowAnimation } from "../animation";

export const CurrentPage = ({
  pageDisplayed,
  setPageDisplayed,
  setNum,
  projectToView,
  setProjectToView,
  setWarningModal,
}) => {
  return (
    <>
      {pageDisplayed.current === ROUTES.team ? (
        <Team />
      ) : pageDisplayed.current === ROUTES.about ? (
        <AboutUs />
      ) : pageDisplayed.current === ROUTES.contact ? (
        <ContactUs />
      ) : null}
      <Services
        setWarningModal={setWarningModal}
        setNum={setNum}
        pageDisplayed={pageDisplayed}
        setPageDisplayed={setPageDisplayed}
      />
      {pageDisplayed.current === "none" ? (
        <></>
      ) : pageDisplayed.current === ROUTES.team || //for "team", "about" and "services" pages there is only close button displayed
        pageDisplayed.current === ROUTES.about ||
        pageDisplayed.current === ROUTES.contact ||
        pageDisplayed.current === ROUTES.services ? (
        <>
          <img
            onClick={() =>
              setPageDisplayed((val) => {
                return { prev: "none", current: "none" };
              })
            }
            className="page-close-btn"
            src={close}
            alt="close"
          />
        </>
      ) : (
        <>
          {/* "mob-hidden" className is added to buttons that play scroll role only for desktop version */}
          <img
            onClick={() =>
              setPageDisplayed((val) => {
                return { prev: "none", current: "none" };
              })
            }
            className="page-close-btn"
            src={close}
            alt="close"
          />
          <img
            onClick={() => setNum(-3)}
            className=""
            src={scrollDown}
            alt=""
          />
          <ArrowAnimation
            name="scroll-up-btn mob-hidden"
            setState={setNum}
            value={-3}
            alt="scroll down"
          />
          {
            <ArrowAnimation
              hidden={pageDisplayed.current === ROUTES.bProjects}
              name="scroll-down-btn mob-hidden"
              setState={setNum}
              value={3}
              alt="scroll down"
            />
          }
        </>
      )}
      <Crypto pageDisplayed={pageDisplayed} />
      <Blockchain pageDisplayed={pageDisplayed} />
      <Metaverse pageDisplayed={pageDisplayed} />
      <DeFi pageDisplayed={pageDisplayed} />
      <SmartContractAudit pageDisplayed={pageDisplayed} />
      <Consulting pageDisplayed={pageDisplayed} />
      <ChainsWeWorkOn pageDisplayed={pageDisplayed} />
      <BlockchainIndustries pageDisplayed={pageDisplayed} />
      {/* <SuccessStories pageDisplayed={pageDisplayed} /> in development*/}
      <BlockchainProjects
        pageDisplayed={pageDisplayed}
        projectToView={projectToView}
        setProjectToView={setProjectToView}
      />
      <AugmentedReality pageDisplayed={pageDisplayed} />
    </>
  );
};
