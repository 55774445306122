import { useState } from "react";
import emailjs from "@emailjs/browser";
import { DesktopVersion, MobileVersion } from "./atoms";
import { useWindowSize } from "../../hooks";

export const ContactUs = () => {
  const [activeInput, setActiveInput] = useState("none");

  // detecting if user is using a mobile
  const { mobile, desktop } = useWindowSize();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_djc481s",
        "template_8t4z65g",
        e.target,
        "c1Tn-onCY4vGbEKfm"
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
    e.target.reset();
  };

  return (
    <>
      {desktop && (
        <DesktopVersion
          activeInput={activeInput}
          setActiveInput={setActiveInput}
          sendEmail={sendEmail}
        />
      )}
      {mobile && (
        <MobileVersion
          activeInput={activeInput}
          setActiveInput={setActiveInput}
          sendEmail={sendEmail}
        />
      )}
    </>
  );
};
