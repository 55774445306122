import "../../css/blockchain css/blockchain.css";
import { BLOCKCHAIN_TEXTS, ROUTES } from "../../constants";

// images

import blockchain from "../../assets/blockchain/blockchain.webp";
import blockchainShadow from "../../assets/blockchain/blockchainShadow.webp";

const DesktopVersion = ({ pageDisplayed }) => {
  return (
    <div
      className={
        pageDisplayed.prev === ROUTES.crypto &&
        pageDisplayed.current === ROUTES.blockchain
          ? "blockchain-container mob-hidden appear-up"
          : pageDisplayed.current === ROUTES.crypto &&
            pageDisplayed.prev === ROUTES.blockchain
          ? "blockchain-container mob-hidden exit-down"
          : pageDisplayed.current === ROUTES.metaverse &&
            pageDisplayed.prev === ROUTES.blockchain
          ? "blockchain-container mob-hidden exit-up"
          : pageDisplayed.current === ROUTES.blockchain &&
            pageDisplayed.prev === ROUTES.metaverse
          ? "blockchain-container mob-hidden appear-down"
          : pageDisplayed.current === ROUTES.blockchain &&
            pageDisplayed.prev === "none"
          ? "blockchain-container mob-hidden"
          : "blockchain-container mob-hidden hidden"
      }
    >
      <div className="blockchain-top-wrapper">
        <div className="gradient-headline-wrapper">
          <h3 className="page-headline gradient-headline">
            {BLOCKCHAIN_TEXTS.headlineGradient}
          </h3>
          <h3 className="page-headline">{BLOCKCHAIN_TEXTS.headline}</h3>
        </div>
        <p className="blockchain-description">{BLOCKCHAIN_TEXTS.description}</p>
      </div>
      <div className="blockchain-bot-wrapper">
        <img className="blockchain-img" src={blockchain} alt="" />
        <img className="blockchain-img-shadow" src={blockchainShadow} alt="" />
        <div className="blockchain-tabs">
          {BLOCKCHAIN_TEXTS.tabs.map((val, index) => {
            return (
              <div className="blockchain-tab" key={index}>
                <div>
                  <div></div>
                </div>
                <p>{val}</p>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

const MobileVersion = ({ pageDisplayed }) => {
  return (
    <div
      className={
        pageDisplayed.prev === ROUTES.crypto &&
        pageDisplayed.current === ROUTES.blockchain
          ? "blockchain-container-mob non-mob-hidden appear-up"
          : pageDisplayed.current === ROUTES.crypto &&
            pageDisplayed.prev === ROUTES.blockchain
          ? "blockchain-container-mob non-mob-hidden exit-down"
          : pageDisplayed.current === ROUTES.metaverse &&
            pageDisplayed.prev === ROUTES.blockchain
          ? "blockchain-container-mob non-mob-hidden exit-up"
          : pageDisplayed.current === ROUTES.blockchain &&
            pageDisplayed.prev === ROUTES.metaverse
          ? "blockchain-container-mob non-mob-hidden appear-down"
          : pageDisplayed.current === ROUTES.blockchain &&
            pageDisplayed.prev === "none"
          ? "blockchain-container-mob non-mob-hidden"
          : "blockchain-container-mob non-mob-hidden hidden"
      }
    >
      <div className="blockchain-top-wrapper-mob">
        <div className="gradient-headline-wrapper-mob">
          <h3 className="gradient-headline">
            {BLOCKCHAIN_TEXTS.headlineGradient}
          </h3>
          <h3>{BLOCKCHAIN_TEXTS.headline}</h3>
        </div>
        <p className="blockchain-description-mob">
          {BLOCKCHAIN_TEXTS.description}
        </p>
      </div>
      <div className="blockchain-bot-wrapper-mob">
        <div className="blockchain-bot-img-wrapper-mob">
          <img className="blockchain-img-mob" src={blockchain} alt="" />
          <img
            className="blockchain-img-shadow-mob"
            src={blockchainShadow}
            alt=""
          />
        </div>
        {BLOCKCHAIN_TEXTS.tabs.map((val, index) => {
          return (
            <div className="blockchain-tab-mob" key={index}>
              <div>
                <div></div>
              </div>
              <p>{val}</p>
            </div>
          );
        })}
      </div>
    </div>
  );
};
export const Blockchain = ({ pageDisplayed }) => {
  return (
    <>
      <DesktopVersion pageDisplayed={pageDisplayed} />
      <MobileVersion pageDisplayed={pageDisplayed} />
    </>
  );
};
