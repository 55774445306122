import { useState } from "react";
import { motion } from "framer-motion";

export const NavArrowAnimation = ({ animation, name }) => {
  // const [animation, setAnimation] = useState(false);

  return (
    <motion.svg
      xmlns="http://www.w3.org/2000/svg"
      width="39"
      height="39"
      fill="none"
      viewBox="0 0 39 39"
      initial={{ x: -30, opacity: 0 }}
      animate={{
        x: animation == name ? 0 : -30,
        opacity: animation == name ? 1 : 0,
      }}
      transition={{
        duration: 0.8,
        delay: 0,
      }}
    >
      <motion.path
        key={animation === name ? 2 : 3}
        initial={{
          pathLength: animation == name ? 0 : 1,
          opacity: animation == name ? 0 : 1,
        }}
        animate={{
          pathLength: animation == name ? 1 : 0,
          opacity: animation == name ? 1 : 0,
        }}
        transition={{
          duration: 1,
          delay: 0,
        }}
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="2.938"
        d="M19.5 35.75c8.975 0 16.25-7.275 16.25-16.25S28.475 3.25 19.5 3.25 3.25 10.525 3.25 19.5s7.275 16.25 16.25 16.25z"
      ></motion.path>
      <motion.path
        key={animation === name ? 1 : null}
        initial={{
          pathLength: animation == name ? 0 : 1,
          opacity: animation == name ? 0 : 1,
        }}
        animate={{
          pathLength: animation == name ? 1 : 0,
          opacity: animation == name ? 1 : 0,
        }}
        transition={{
          duration: 1,
          delay: 0,
        }}
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2.938"
        d="M17.453 25.236l5.72-5.736-5.72-5.736"
      ></motion.path>
    </motion.svg>
  );
};
