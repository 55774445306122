import { useState } from "react";
import { motion } from "framer-motion";

export const FacebookAnimation = () => {
  const handleClick = () => {
    window.open("https://www.facebook.com/peakit0", "_blank");
  };
  const [animation, setAnimation] = useState(false);
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      viewBox="0 0 24 24"
      onMouseOver={() => setAnimation(true)}
      onMouseLeave={() => setAnimation(false)}
      style={{ cursor: "pointer" }}
      onClick={handleClick} // Call handleClick on click
    >
      <motion.path
        key={animation ? 1 : null}
        initial={{
          pathLength: animation ? 0 : 1,
        }}
        animate={{
          pathLength: 1,
          opacity: 1,
        }}
        transition={{
          duration: 2,
          delay: 0,
          repeat: Infinity,
        }}
        d="M18 2h-3a5 5 0 00-5 5v3H7v4h3v8h4v-8h3l1-4h-4V7a1 1 0 011-1h3z"
      ></motion.path>
    </svg>
  );
};
