import { FOOTER_TEXTS } from "../../../constants";
import "../footer.css";
import {
  FacebookAnimation,
  TwitterAnimation,
  LinkedinAnimation,
  SoundAnimation,
  MouseAnimation,
  MailAnimation,
} from "../../../animation";

// images

import mouse from "../../../assets/footer/mouse.webp";

export const DesktopVersion = () => {
  return (
    <div className="footer-container mob-hidden">
      <div className="footer-left-side">
        <MailAnimation />
        <p className="footer-contact" onClick={() => (window.location = "mailto:erik@peakit.io")}>{FOOTER_TEXTS.contact}</p>
        <div className="footer-line"></div>

        <FacebookAnimation />
        <TwitterAnimation />
        <LinkedinAnimation />
        <div className="footer-line"></div>
        {/* <p className="footer-sound">
          <SoundAnimation />
          sound
        </p>
        <div className="footer-line"></div> */}

        <p className="menu-C-btn">© 2022. PeakIT. All rights reserved.</p>
      </div>
      <div className="footer-right-side">
        <p>{FOOTER_TEXTS.more}</p>
        <MouseAnimation />
      </div>
    </div>
  );
};
