import { WhatsappAnimation } from "../../../animation";

import { CONTACT_US_TEXTS } from "../../../constants";
import "../contactUs.css";
import { motion } from "framer-motion";

export const DesktopVersion = ({ activeInput, setActiveInput, sendEmail }) => {
  return (
    <div className="about-container mob-hidden">
      <div className="about-header-wrapper">
        <motion.div
          initial={{ x: "-50vw", opacity: 0 }}
          animate={{ x: 0, opacity: 1 }}
          transition={{ duration: 1, type: "spring" }}
          className="gradient-headline-wrapper"
        >
          <h3 className="page-headline gradient-headline">
            {CONTACT_US_TEXTS.headlineGradient}
          </h3>
          <h3 className="page-headline">{CONTACT_US_TEXTS.headline}</h3>
        </motion.div>
      </div>
      <div className="contact-content-wrapper">
        <form onSubmit={sendEmail} className="contact-form">
          <section
            className={
              activeInput === "name"
                ? "contact-single-input contact-single-input-active"
                : "contact-single-input"
            }
          >
            <label htmlFor="name">Full Name*</label>
            <input
              id="name"
              onClick={() => setActiveInput("name")}
              type="text"
              name="name"
            />
          </section>
          <section
            className={
              activeInput === "email"
                ? "contact-single-input contact-single-input-active"
                : "contact-single-input"
            }
          >
            <label htmlFor="email">Email*</label>
            <input
              id="email"
              type="email"
              onClick={() => setActiveInput("email")}
              name="email"
            />
          </section>
          <section
            className={
              activeInput === "comment"
                ? "contact-single-input contact-textarea contact-single-input-active"
                : "contact-single-input contact-textarea"
            }
          >
            <label htmlFor="message">Comment*</label>
            <textarea
              id="message"
              onClick={() => setActiveInput("comment")}
              type="text"
              name="message"
            />
          </section>
          <section className="contact-btns-wrapper">
            <button className="contact-submit-btn">Send</button>
            <WhatsappAnimation position={35} width="2.2vw" height="2.2vw" />
          </section>
        </form>
      </div>
    </div>
  );
};
