export const ROUTES = {
  services: "services",
  crypto: "Crypto Development",
  blockchain: "Blockchain Apps",
  metaverse: "NFT / MetaVerse Development",
  defi: "DeFi Development",
  smartContractAudit: "Smart Contract Audit",
  smartContractAudit2: "Consulting",
  chains: "chains we work on",
  blockchainIndustries: "Blockchain in Different Industries",
  ar: "Augmented Reality Development",
  bSuccessStories: "blockchain success stories",
  bProjects: "blockchain projects",
  about: "about",
  team: "team",
  contact: "contact",
};
export const PAGES_LIST = [
  "services",
  "Crypto Development",
  "Blockchain Apps",
  "NFT / MetaVerse Development",
  "DeFi Development",
  "Smart Contract Audit",
  "Consulting",
  "chains we work on",
  "Blockchain in Different Industries",
  "Augmented Reality Development",
  // "blockchain success stories", in development
  "blockchain projects",
];
