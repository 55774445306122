import { HEADER_TEXTS } from "../../../constants";
import "../header.css";
import { Logo } from "../../../assets";

// images
import headerMenu from "../../../assets/header/headerMenu.webp";
import close from "../../../assets/header/close.webp";

export const MobileVersion = ({ setMenu, menu }) => {
  return (
    <div className="header-container-mob non-mob-hidden">
      <Logo height="5vw" fill="#87D9F9" fill2="#87D9F9" />
      <img
        onClick={() => setMenu((prev) => !prev)}
        src={menu ? close : headerMenu}
        alt="menu"
      />
    </div>
  );
};
