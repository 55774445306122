export const Logo = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    id="Layer_2"
    viewBox="0 0 335.85 72.94"
    {...props}
  >
    <defs>
      <linearGradient
        id="linear-gradient"
        x1={41.68}
        x2={41.68}
        y1={64.51}
        y2={11.11}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#508ef2" />
        <stop offset={1} stopColor="#8ae4fa" />
      </linearGradient>
      <linearGradient
        xlinkHref="#linear-gradient"
        id="linear-gradient-2"
        x1={41.68}
        x2={41.68}
        y1={64.51}
        y2={11.11}
      />
      <style>{".cls-2{fill:#fff}"}</style>
    </defs>
    <g id="Layer_1-2">
      <path
        d="M83.37 41.25H51.33l-9.65-9.56-9.66 9.56H0L41.68 0l41.69 41.25z"
        style={{
          fill: props.fill,
        }}
      />
      <path
        d="M83.37 72.94H51.33l-9.65-9.55-9.66 9.55H0l32.02-31.69h19.31l32.04 31.69z"
        style={{
          fill: props.fill2,
        }}
      />
      <path
        d="M132.85 13.97c11.54 0 16.47 7.13 16.47 15.33 0 7.74-4.48 16.09-16.47 16.09h-15.94c-.83 0-1.29.38-1.29 1.29V65.2h-8.96V44.93c0-5.77 2.43-8.12 8.05-8.12h18.44c5.09 0 7.13-3.49 7.13-7.06s-2.2-6.83-7.06-6.83h-26.64v-8.96h26.26ZM179.24 25.12c9.49 0 13.51 4.55 13.51 12.3s-3.72 11.54-13.59 11.54h-19.05v2.89c0 3.19 1.75 5.54 6.15 5.54h24.52v7.82h-24.52c-10.02 0-13.97-5.62-13.97-13.21V38.34c0-7.59 3.95-13.21 13.97-13.21h12.98Zm-19.13 17.53h18.82c4.33 0 5.92-1.82 5.92-4.78s-1.75-5.01-5.69-5.01h-12.9c-4.4 0-6.15 2.43-6.15 5.54v4.25ZM222.48 25.12c10.02 0 13.97 5.62 13.97 13.21v18.83c0 5.77-1.52 8.05-8.27 8.05h-18.75c-9.49 0-13.44-4.63-13.44-12.22s3.64-11.54 13.51-11.54h19.05v-3.04c0-3.11-1.75-5.54-6.15-5.54h-23.99v-7.74h24.06Zm6.07 30.21v-7.51h-18.9c-4.25 0-5.77 1.75-5.77 4.71s1.59 4.86 5.62 4.86h16.85c1.6 0 2.2-.61 2.2-2.05ZM250.27 7.74v34.01h3.72c6.38 0 7.67-.3 10.02-4.55l6.68-12.07h8.73l-6.98 12.6c-1.97 3.57-3.87 5.77-7.13 7.13 3.8 1.06 5.77 3.57 8.2 7.74l7.21 12.6h-9.03l-6.68-11.84c-2.35-4.17-3.95-4.86-11.01-4.86h-3.72v16.7h-8.05V7.74h8.05ZM293.35 13.97v51.24h-8.96V13.97h8.96ZM335.85 13.97v8.96H320.9v42.28h-9.03V22.92h-14.95v-8.96h38.94Z"
        className="cls-2"
      />
    </g>
  </svg>
);
