import { useEffect, useRef, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination } from "swiper";
import { TEAM_TEXTS } from "../constants";

// css
import "../css/team.css";
import "swiper/css";

// images
import Arrow from "../assets/common/ArrowRight.webp";
import ArrowLeft from "../assets/common/ArrowLeft.webp";
import calendar from "../assets/blockchain projects/calendar.webp";
import scrollDown from "../assets/common/scrollDown.webp";
import scrollUp from "../assets/common/scrollUp.webp";
import close from "../assets/common/close.webp";

const DesktopVersion = ({ setPagination, prevRef, nextRef, slides }) => {
  return (
    <div className="team-container mob-hidden">
      <div className="team-arrow-wrapper">
        <img
          ref={prevRef}
          className="team-arrow"
          src={ArrowLeft}
          alt="left arrow"
        />
        <img
          ref={nextRef}
          className="team-arrow "
          src={Arrow}
          alt="right arrow"
        />
      </div>
      <div className="team-content-wrapper">
        <Swiper
          modules={[Navigation, Pagination]}
          pagination={{
            clickable: false,
            type: "custom",
            renderCustom: (swiper, children) => setPagination(children),
          }}
          onInit={(swiper) => {
            swiper.params.navigation.prevEl = prevRef.current;
            swiper.params.navigation.nextEl = nextRef.current;
            swiper.navigation.init();
            swiper.navigation.update();
          }}
          allowTouchMove={false}
          spaceBetween={100}
          slidesPerView={3}
          scrollbar={{ draggable: false }}
        >
          {TEAM_TEXTS.members.map((val, index) => {
            return (
              <SwiperSlide key={index}>
                <div
                  className={
                    slides.middle === index
                      ? "team-single-slide team-middle-slide"
                      : slides.first === index
                      ? "team-single-slide team-first-slide"
                      : slides.next === index
                      ? "team-single-slide team-next-slide"
                      : slides.prev === index
                      ? "team-single-slide team-prev-slide"
                      : slides.next2 === index
                      ? "team-single-slide team-next2-slide"
                      : "team-single-slide"
                  }
                >
                  <img src={val.img} alt="slide img" />
                  <p>{val.name}</p>
                </div>
              </SwiperSlide>
            );
          })}
        </Swiper>
      </div>
    </div>
  );
};
const MobileVersion = ({ setPagination, prevRef, nextRef, slides }) => {
  return (
    <div className="team-container-mob non-mob-hidden">
      <h3 className="team-headline-mob">{TEAM_TEXTS.headline}</h3>
      <div className="team-content-wrapper-mob">
        <Swiper
          modules={[Navigation, Pagination]}
          pagination={{
            clickable: false,
            type: "custom",
            renderCustom: (swiper, children) => setPagination(children),
          }}
          onInit={(swiper) => {
            swiper.params.navigation.prevEl = prevRef.current;
            swiper.params.navigation.nextEl = nextRef.current;
            swiper.navigation.init();
            swiper.navigation.update();
          }}
          spaceBetween={50}
          slidesPerView={1}
        >
          {TEAM_TEXTS.members.map((val, index) => {
            return (
              <SwiperSlide key={index}>
                <div className="team-single-slide-mob">
                  <img src={val.img} alt="slide img" />
                  <p>{val.name}</p>
                </div>
              </SwiperSlide>
            );
          })}
          <div className="team-arrow-wrapper-mob">
            <img
              ref={prevRef}
              className="team-arrow-mob"
              src={ArrowLeft}
              alt="left arrow"
            />
            <img
              ref={nextRef}
              className="team-arrow-mob"
              src={Arrow}
              alt="right arrow"
            />
          </div>
        </Swiper>
      </div>
    </div>
  );
};

export const Team = () => {
  const prevRef = useRef(null);
  const nextRef = useRef(null);
  const [pagination, setPagination] = useState(1);
  const slides = {
    middle: pagination,
    first: pagination - 1,
    prev: pagination - 2,
    next: pagination + 1,
    next2: pagination + 2,
  };

  // decets if user is using mobile
  const [width, setWidth] = useState(window.innerWidth);

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  const isMobile = width <= 768;

  return (
    <>
      <DesktopVersion
        prevRef={prevRef}
        nextRef={nextRef}
        setPagination={setPagination}
        slides={slides}
      />
      {isMobile && (
        <MobileVersion
          prevRef={prevRef}
          nextRef={nextRef}
          setPagination={setPagination}
          slides={slides}
        />
      )}
    </>
  );
};
