// css
import "../projects.css";
import close from "../../../assets/common/close.webp";

export const ProjectViewDesktop = ({ setProjectToView, projectToView }) => {
  return (
    <div className="pv-container">
      <img
        onClick={() => setProjectToView({ status: "closed", project: {} })}
        className="pv-page-close-btn"
        src={close}
        alt="close"
      />
      <article className="pv-content-wrapper">
        <section className="pv-header">
          {projectToView.project.title0 && (
            <h3 className="page-headline">{projectToView.project.title0}</h3>
          )}
          {projectToView.project.gradientTitle1 && (
            <h3 className="page-headline gradient-headline">
              {projectToView.project.gradientTitle1}
            </h3>
          )}
          <h3 className="page-headline">{projectToView.project.title}</h3>
          {projectToView.project.gradientTitle2 && (
            <h3 className="page-headline gradient-headline">
              {projectToView.project.gradientTitle2}
            </h3>
          )}
          {projectToView.project.title2 && (
            <h3 className="page-headline">{projectToView.project.title2}</h3>
          )}
        </section>
        <section>
          <h3 className="pv-section-headline">Description:</h3>
          <p className="pv-section-text">{projectToView.project.description}</p>
        </section>
        <section>
          <h3 className="pv-section-headline">{projectToView.project.label}</h3>
          {projectToView.project.content.map((v, i) => {
            return (
              <p className="pv-section-text" key={i}>
                {v}
              </p>
            );
          })}
        </section>
        <section style={{"marginBottom": "8vw"}}>
          <h3 className="pv-section-headline">Technological Stack:</h3>
          {projectToView.project.stacks.map((v, i) => {
            return (
              <p className="pv-section-text" key={i}>
                {v}
              </p>
            );
          })}
        </section>
      </article>
    </div>
  );
};
