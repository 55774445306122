import "../../css/blockchain css/smartContractAudit.css";
import { ROUTES, CONSULTING_TEXTS } from "../../constants";

// images
import audit from "../../assets/audit/audit.webp";
import consulting from "../../assets/audit/consulting.jpg";

const DesktopVersion = ({ pageDisplayed }) => {
  return (
    <div
      className={
        pageDisplayed.prev === ROUTES.smartContractAudit &&
        pageDisplayed.current === ROUTES.smartContractAudit2
          ? "smart-contract-audit-container mob-hidden appear-up"
          : pageDisplayed.current === ROUTES.smartContractAudit &&
            pageDisplayed.prev === ROUTES.smartContractAudit2
          ? "smart-contract-audit-container mob-hidden exit-down"
          : pageDisplayed.current === ROUTES.chains &&
            pageDisplayed.prev === ROUTES.smartContractAudit2
          ? "smart-contract-audit-container mob-hidden exit-up"
          : pageDisplayed.current === ROUTES.smartContractAudit2 &&
            pageDisplayed.prev === ROUTES.chains
          ? "smart-contract-audit-container mob-hidden appear-down"
          : pageDisplayed.current === ROUTES.smartContractAudit2 &&
            pageDisplayed.prev === "none"
          ? "smart-contract-audit-container mob-hidden"
          : "smart-contract-audit-container mob-hidden hidden"
      }
    >
      <div className="smart-contract-audit-header">
        <h3 className="page-headline gradient-headline">
          {CONSULTING_TEXTS.headline}
        </h3>
      </div>
      <div className="smart-contract-audit-content-wrapper">
        <img src={consulting} alt="" />
        <div>
          {CONSULTING_TEXTS.description.map((val, index) => {
            return (
              <p className="consulting-description" key={index}>
                {val}
              </p>
            );
          })}
        </div>
      </div>
    </div>
  );
};

const MobileVersion = ({ pageDisplayed }) => {
  return (
    <div
      className={
        pageDisplayed.prev === ROUTES.smartContractAudit &&
        pageDisplayed.current === ROUTES.smartContractAudit2
          ? "smart-contract-audit-container-mob non-mob-hidden appear-up"
          : pageDisplayed.current === ROUTES.smartContractAudit &&
            pageDisplayed.prev === ROUTES.smartContractAudit2
          ? "smart-contract-audit-container-mob non-mob-hidden exit-down"
          : pageDisplayed.current === ROUTES.chains &&
            pageDisplayed.prev === ROUTES.smartContractAudit2
          ? "smart-contract-audit-container-mob non-mob-hidden exit-up"
          : pageDisplayed.current === ROUTES.smartContractAudit2 &&
            pageDisplayed.prev === ROUTES.chains
          ? "smart-contract-audit-container-mob non-mob-hidden appear-down"
          : pageDisplayed.current === ROUTES.smartContractAudit2 &&
            pageDisplayed.prev === "none"
          ? "smart-contract-audit-container-mob non-mob-hidden"
          : "smart-contract-audit-container-mob non-mob-hidden hidden"
      }
    >
      <div className="gradient-headline-wrapper-mob">
        <h3 className="page-headline gradient-headline">
          {CONSULTING_TEXTS.headline}
        </h3>
      </div>
      <div className="smart-contract-audit-content-wrapper-mob">
        <img src={consulting} alt="" />
        <div>
          {CONSULTING_TEXTS.description.map((val, index) => {
            return (
              <p className="consulting-description" key={index}>
                {val}
              </p>
            );
          })}
        </div>
      </div>
    </div>
  );
};
export const Consulting = ({ pageDisplayed }) => {
  return (
    <>
      <DesktopVersion pageDisplayed={pageDisplayed} />
      <MobileVersion pageDisplayed={pageDisplayed} />
    </>
  );
};
