import "../../css/blockchain css/metaverse.css";
import { METAVERSE_TEXTS, ROUTES } from "../../constants";

// images

import marketplace from "../../assets/metaverse/marketplace.webp";
import gameDev from "../../assets/metaverse/gameDev.webp";
import metaverseBorder from "../../assets/metaverse/metaverseBorder.webp";
import metaverseCircles from "../../assets/metaverse/metaverseCircles.webp";

const DesktopVersion = ({ pageDisplayed }) => {
  return (
    <div
      className={
        pageDisplayed.prev === ROUTES.blockchain &&
        pageDisplayed.current === ROUTES.metaverse
          ? "metaverse-container mob-hidden appear-up"
          : pageDisplayed.current === ROUTES.blockchain &&
            pageDisplayed.prev === ROUTES.metaverse
          ? "metaverse-container mob-hidden exit-down"
          : pageDisplayed.current === ROUTES.defi &&
            pageDisplayed.prev === ROUTES.metaverse
          ? "metaverse-container mob-hidden exit-up"
          : pageDisplayed.current === ROUTES.metaverse &&
            pageDisplayed.prev === ROUTES.defi
          ? "metaverse-container mob-hidden appear-down"
          : pageDisplayed.current === ROUTES.metaverse &&
            pageDisplayed.prev === "none"
          ? "metaverse-container mob-hidden"
          : "metaverse-container mob-hidden hidden"
      }
    >
      <div className="metaverse-headline-wrapper">
        <h3 className="page-headline gradient-headline">
          {METAVERSE_TEXTS.headline1}
        </h3>
        <div></div>
        <h3 className="page-headline gradient-headline">
          {METAVERSE_TEXTS.headline2}
        </h3>
        <h3 className="page-headline">{METAVERSE_TEXTS.headline3}</h3>
      </div>
      <div className="metaverse-content-wrapper">
        <p>
          {METAVERSE_TEXTS.description}
          <br />
          <br />
          {METAVERSE_TEXTS.description2}
        </p>
        <div>
          <div className="metaverse-tab">
            <img
              className="metaverse-border"
              src={metaverseBorder}
              alt="decor"
            />
            <img
              className="metaverse-icon"
              src={marketplace}
              alt="NFT Marketplace"
            />
            <p>{METAVERSE_TEXTS.tabMarketplace}</p>
          </div>
          <div className="metaverse-tab">
            <img
              className="metaverse-border"
              src={metaverseBorder}
              alt="decor"
            />
            <img
              className="metaverse-icon"
              src={gameDev}
              alt="NFT Marketplace"
            />
            <p>{METAVERSE_TEXTS.tabGameDev}</p>
          </div>
        </div>
      </div>
      <img className="metaverse-circles" src={metaverseCircles} alt="circles" />
    </div>
  );
};

const MobileVersion = ({ pageDisplayed }) => {
  return (
    <div
      className={
        pageDisplayed.prev === ROUTES.blockchain &&
        pageDisplayed.current === ROUTES.metaverse
          ? "metaverse-container-mob non-mob-hidden appear-up"
          : pageDisplayed.current === ROUTES.blockchain &&
            pageDisplayed.prev === ROUTES.metaverse
          ? "metaverse-container-mob non-mob-hidden exit-down"
          : pageDisplayed.current === ROUTES.defi &&
            pageDisplayed.prev === ROUTES.metaverse
          ? "metaverse-container-mob non-mob-hidden exit-up"
          : pageDisplayed.current === ROUTES.metaverse &&
            pageDisplayed.prev === ROUTES.defi
          ? "metaverse-container-mob non-mob-hidden appear-down"
          : pageDisplayed.current === ROUTES.metaverse &&
            pageDisplayed.prev === "none"
          ? "metaverse-container-mob non-mob-hidden"
          : "metaverse-container-mob non-mob-hidden hidden"
      }
    >
      <div className="metaverse-headline-wrapper-mob">
        <h3 className="metaverse-page-headline-mob gradient-headline">
          {METAVERSE_TEXTS.headline1}
        </h3>
        <div className="mettaverse-circle-dec"></div>
        <h3 className="metaverse-page-headline-mob gradient-headline">
          {METAVERSE_TEXTS.headline2}
        </h3>
        <h3 className="metaverse-page-headline-mob">
          {METAVERSE_TEXTS.headline3}
        </h3>
      </div>
      <div className="metaverse-content-wrapper-mob">
        <p>
          {METAVERSE_TEXTS.description}
          <br />
          <br />
          {METAVERSE_TEXTS.description2}
        </p>
        <div>
          <div className="metaverse-tab-mob">
            <img
              className="metaverse-border-mob"
              src={metaverseBorder}
              alt="decor"
            />
            <img
              className="metaverse-icon-mob"
              src={marketplace}
              alt="NFT Marketplace"
            />
            <p>{METAVERSE_TEXTS.tabMarketplace}</p>
          </div>
          <div className="metaverse-tab-mob">
            <img
              className="metaverse-border-mob"
              src={metaverseBorder}
              alt="decor"
            />
            <img
              className="metaverse-icon-mob"
              src={gameDev}
              alt="NFT Marketplace"
            />
            <p>{METAVERSE_TEXTS.tabGameDev}</p>
          </div>
        </div>
      </div>
      <img
        className="metaverse-circles-mob"
        src={metaverseCircles}
        alt="circles"
      />
    </div>
  );
};

export const Metaverse = ({ pageDisplayed }) => {
  return (
    <>
      <DesktopVersion pageDisplayed={pageDisplayed} />
      <MobileVersion pageDisplayed={pageDisplayed} />
    </>
  );
};
