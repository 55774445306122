import { useEffect, useState } from "react";
import { motion } from "framer-motion";

import arrowBackground from "./arrowBackground.png";

export const ArrowAnimation = ({ setState, name, value, hidden }) => {
  const [animation, setAnimation] = useState(false);

  // triggers animation every 4 second
  useEffect(() => {
    setTimeout(() => {
      setAnimation(!animation);
    }, "4000");
  }, [animation]);

  return (
    <span
      onClick={() => setState(value)}
      className={hidden ? `${name} hidden` : name}
    >
      <img src={arrowBackground} alt="background" />
      <motion.svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        viewBox="0 0 24 24"
        initial={{ y: -3 }}
      >
        <motion.path
          key={animation ? 2 : null}
          initial={{
            pathLength: !animation ? 0 : 0,
            opacity: !animation ? 0 : 0,
          }}
          animate={{
            pathLength: 0.85,
            opacity: 0.8,
          }}
          transition={{
            duration: 1,
            delay: 0,
          }}
          d="M12 4L12 20"
        />
        <motion.path
          key={animation ? 1 : 3}
          initial={{
            pathLength: !animation ? 0 : 0,
            rotate: 180,
            x: -3,
            opacity: !animation ? 0 : 0,
          }}
          animate={{
            pathLength: 1,
            opacity: 0.8,
          }}
          transition={{
            duration: 1,
            delay: 1,
          }}
          d="M9 14L15 20"
        />
        <motion.path
          key={animation ? 4 : 5}
          initial={{
            pathLength: !animation ? 0 : 0,
            rotate: 180,
            opacity: !animation ? 0 : 0,
          }}
          animate={{
            pathLength: 1,
            opacity: 0.8,
          }}
          transition={{
            duration: 1,
            delay: 1,
          }}
          d="M18 14L12 20"
        />
      </motion.svg>
    </span>
  );
};
