import { useWindowSize } from "../../hooks";
import { DesktopVersion, MobileVersion } from "./atoms";

export const AboutUs = () => {
  // detecting if user is using a mobile

  const { mobile, desktop } = useWindowSize();
  return (
    <>
      {desktop && <DesktopVersion />}
      {mobile && <MobileVersion />}
    </>
  );
};
