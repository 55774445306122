import { WhatsappAnimation } from "../../../animation";
import { CONTACT_US_TEXTS } from "../../../constants";
import "../contactUs.css";

export const MobileVersion = ({ activeInput, setActiveInput, sendEmail }) => {
  return (
    <div className="contact-container-mob non-mob-hidden">
      <div className="contact-header-wrapper-mob">
        <div className="gradient-headline-wrapper-mob">
          <h3 className="contact-headline-mob gradient-headline">
            {CONTACT_US_TEXTS.headlineGradient}
          </h3>
          <h3 className="contact-headline-mob">{CONTACT_US_TEXTS.headline}</h3>
        </div>
      </div>
      <div className="contact-content-wrapper-mob">
        <form onSubmit={sendEmail} className="contact-form-mob">
          <section
            className={
              activeInput === "name"
                ? "contact-single-input-mob contact-single-input-active-mob"
                : "contact-single-input-mob"
            }
          >
            <label htmlFor="name">Full Name*</label>
            <input
              name="name"
              id="name"
              onClick={() => setActiveInput("name")}
              type="text"
            />
          </section>
          <section
            className={
              activeInput === "email"
                ? "contact-single-input-mob contact-single-input-active-mob"
                : "contact-single-input-mob"
            }
          >
            <label htmlFor="email">Email*</label>
            <input
              name="email"
              id="email"
              onClick={() => setActiveInput("email")}
              type="text"
            />
          </section>
          <section
            className={
              activeInput === "comment"
                ? "contact-single-input-mob contact-textarea-mob contact-single-input-active-mob"
                : "contact-single-input-mob contact-textarea-mob"
            }
          >
            <label htmlFor="message">Comment*</label>
            <textarea
              name="message"
              id="message"
              onClick={() => setActiveInput("comment")}
              type="text"
            />
          </section>
          <section className="contact-btns-wrapper-mob">
            <button className="contact-submit-btn-mob">Send</button>
            <WhatsappAnimation position={25} width="25px" height="25px" />
          </section>
        </form>
      </div>
    </div>
  );
};
