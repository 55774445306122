// images
import BlockchainInAdvertising from "../assets/blockchainIndustries/blockchainIn-advertising.webp";
import BlockchainInArt from "../assets/blockchainIndustries/blockchainIn-advertising.webp";
import BlockchainInEducation from "../assets/blockchainIndustries/blockchainIn-education.webp";
import BlockchainInGame from "../assets/blockchainIndustries/blockchainIn-game.webp";
import BlockchainInLogistics from "../assets/blockchainIndustries/blockchainIn-logistics.webp";
import BlockchainInWallet from "../assets/blockchainIndustries/blockchainIn-wallet.webp";
import storiesPerson from "../assets/success stories/storiesPerson.webp";
import teamMember from "../assets/team/teamMember.webp";

// project images
import lottery from "../assets/blockchain projects/lottery.png";
import metanemesis from "../assets/blockchain projects/Metanemesis.jpg";
import deck from "../assets/blockchain projects/deck.jpg";
import remex from "../assets/blockchain projects/Remex.png";
import brainnation from "../assets/blockchain projects/Brainnation.jpg";
import warpjump from "../assets/blockchain projects/warpjump.jpg";
import wine from "../assets/blockchain projects/Wine.png";
import dsc from "../assets/blockchain projects/dsc.png";

import sosauto from "../assets/blockchain projects/sosauto.jpg";


export const HEADER_TEXTS = {
  logo1: "freepkie",
  logo2: "development",
  contact: "contact us",
  menu: "menu",
  menuO: "close",
};

export const FOOTER_TEXTS = {
  contact: "irakli@peakit.io",
  sound: "sound",
  more: "discover more",
};

// back to city
export const MENU_NAV = [
  { name: "services", path: "services" },
  { name: "about us", path: "about" },
  { name: "team", path: "team" },
  { name: "contact us", path: "contact" },
];

export const SERVICES_TEXTS = {
  headline: "services",
  leftName: "blockchain",
  left: [
    "Crypto Development",
    "Blockchain Apps",
    "NFT / MetaVerse Development",
    "DeFi Development",
    "Smart Contract Audit",
    "Consulting",
  ],
  rightName: "other services",
  right: [
    "Web Development",
    "Mobile Development",
    "Augmented Reality Development",
  ],
};
export const BLOCKCHAIN_TEXTS = {
  headline: "Apps",
  headlineGradient: "Blockchain",
  description:
    "Blockchain app development services provide enhanced security, transparency and trustless systems to transform your operations. Together with us, you can unlock the full power of blockchain technology with our expert app development services. Our team has extensive experience creating cutting-edge solutions for crypto marketplaces, cryptocurrency wallets, and exchanges. We also specialize in custom decentralized applications (DApps) tailored to your needs.",
  tabs: [
    "Crypto Marketplace",
    "Cryptocurrency Wallets",
    "Cryptocurrency Exchanges",
    "Custom dApps",
  ],
};

export const METAVERSE_TEXTS = {
  headline1: "NFT",
  headline2: "MetaVerse",
  headline3: " Development",
  description:
    "Our team's experience in creating NFT-based games, marketplaces and Metaverse solutions, can help businesses tap into the digital asset revolution. We specialize in developing and implementing NFTs, from art and collectibles to virtual real estate and gaming items, to drive value for companies. ",
  description2:
    "Our other specialty is creating immersive, decentralized virtual worlds for businesses, which is more known as Metaverse. Metaverse development solutions will allow you to bring your vision to reality and stay ahead of the curve in the Metaverse revolution.",
  tabMarketplace: "NFT Marketplaces",
  tabGameDev: "NFT Game Development",
};

export const CRYPTO_TEXTS = {
  headline: "Development",
  headlineGradient: "Crypto",

  descriptionSolidity:
    "Our team of experienced Solidity developers can help you create and deploy smart contracts on various blockchains. We specialize in creating secure and efficient smart contracts for a variety of use cases.",
  descriptionWeb3:
    "We offer web3 development services to build decentralized applications (dApps) that run on blockchain technology. Our developers use the latest tools and technologies to create interactive and user-friendly dApps.",
  descriptionSmart:
    "We provide smart contract development services to create self-executing digital contracts on the blockchain. Our team has extensive experience in creating smart contracts that are secure, efficient and compliant with industry standards.",
  descriptionEnterprise:
    "Our blockchain experts can help you implement blockchain technology in your business operations to increase efficiency, transparency, and security. We provide services such as blockchain consulting, development and integration of blockchain solutions.",
  tabTop: "Web 3 Development",
  tabLeft: "Solidity Development",
  tabRight: "Smart Contract Development",
  tabBot: "Enterprise Blockchain",
};

export const DEFI_TEXTS = {
  headline: "DeFi",
  headline1: "Development",
  tabs: [
    "Exclusive solution DeFi platform accelerator",
    "DeFi exchange development",
    "DeFi wallet development",
    "DeFi lending and borrowing platform development",
    "DeFi staking development",
    "DeFi smart contract development",
  ],
};
export const CHAINS_WE_WORK_ON_TEXTS = {
  headline: "Main",
  headlineGradient: "Chains",
  headline1: "We Work On",
  description:
    "We specializes in working with a wide array of blockchain networks, including both EVM-based and non-EVM-based platforms. We have extensive experience in building and deploying smart contracts and decentralized applications, harnessing the unique features and capabilities of each blockchain to provide innovative solutions for the decentralized ecosystem. Our expertise extends to various high-performance networks, allowing us to explore new possibilities and contribute to the growth of the blockchain industry. Through our diverse experience and deep knowledge of these networks, we strive to deliver cutting-edge solutions that drive the advancement of the decentralized space.",
  tabs: [
    "Ethereum",
    "Solana",
    "Binance Smart Chain ",
    "Arbitrum",
    "Polygon",
    "Avalanche",
    "Optimism",
    "Fantom",
    "Cronos",
    "Klaytn",
    "Astar",
    "Telos",
    "IoTeX",
    "ThunderCore",
    "Wanchain",
  ],
};

export const AUGMENTED_REALITY_TEXTS = {
  headlineGradient: "Augmented",
  headline: "Reality",
  description:
    "Augmented Reality (AR) is a groundbreaking technology that seamlessly blends the virtual world with the real world. At PeakIT, we are at the forefront of AR innovation, harnessing its power to revolutionize user experiences across various industries. With our expertise in creating interactive Face Filters, immersive Virtual Showrooms, captivating AR Sweepstakes, entertaining AR Games, and cutting-edge WebAR and Mobile AR Apps, we open doors to endless possibilities. AR not only captivates audiences but also empowers businesses to engage customers in new and exciting ways. Join us on this transformative journey as we unlock the potential of Augmented Reality and redefine the future of immersive experiences.",
  tabs: [
    {
      headline: "Face Filters",
      description:
        "Engage your audience with captivating and interactive face filters using our Augmented Reality expertise.",
    },
    {
      headline: "Virtual Showrooms",
      description:
        "Step into the future of retail with lifelike and immersive Virtual Showrooms for showcasing products like never before.",
    },
    {
      headline: "AR Sweepstakes",
      description:
        "Elevate your marketing campaigns with exciting AR Sweepstakes that captivate users and drive brand loyalty.",
    },
    {
      headline: "AR Games",
      description:
        "Gamify your brand with captivating Augmented Reality games that leverage real-world surroundings for immersive experiences.",
    },
    {
      headline: "Web AR",
      description:
        "Embrace the future of AR experiences with our WebAR solutions, accessible directly through web browsers without the need for app installations.",
    },
    {
      headline: "Mobile AR Apps",
      description:
        "Delight users on the go with transformative and interactive Mobile AR Apps for entertainment, education, retail, and more.",
    },
  ],
  tabsMob: [
    {
      headline: "Face Filters",
      description:
        "Engage your audience with captivating and interactive face filters using our Augmented Reality expertise.",
      headline2: "Virtual Showrooms",
      description2:
        "Step into the future of retail with lifelike and immersive Virtual Showrooms for showcasing products like never before.",
    },
    {
      headline: "AR Sweepstakes",
      description:
        "Elevate your marketing campaigns with exciting AR Sweepstakes that captivate users and drive brand loyalty.",
      headline2: "AR Games",
      description2:
        "Gamify your brand with captivating Augmented Reality games that leverage real-world surroundings for immersive experiences.",
    },
    {
      headline: "Web AR",
      description:
        "Embrace the future of AR experiences with our WebAR solutions, accessible directly through web browsers without the need for app installations.",
      headline2: "Mobile AR Apps",
      description2:
        "Delight users on the go with transformative and interactive Mobile AR Apps for entertainment, education, retail, and more.",
    },
  ],
};
export const BLOCKCHAIN_INDUSTRIES_TEXTS = {
  headline: "Blockchain",
  headline1: "benefits for different industries",
  tabs: [
    {
      headline: "Finance and Banking",
      img: BlockchainInWallet,
      tabs: [
        "Increased transparency and improved security",
        "Reduced costs ",
        "Faster transactions",
        "Increased accessibility",
        "Improved compliance and regulatory reporting",
      ],
    },
    {
      headline: "Advertising",
      img: BlockchainInAdvertising,
      tabs: [
        "Better targeting",
        "Decentralized advertising networks",
        "Transparent and tamper-proof record of add campaign",
        "Better ad fraud detection",
        "Increased engagement and fan experience",
      ],
    },
    {
      headline: "Gaming",
      img: BlockchainInGame,
      tabs: [
        "In-game item ownership and trading",
        "Decentralized gaming marketplaces",
        "Trustless gaming",
        "Fair and transparent game economics",
        "New forms of gaming",
      ],
    },
    {
      headline: "Logistics",
      img: BlockchainInLogistics,
      tabs: [
        "Improved supply chain visibility",
        "Digital bills of lading",
        "Decentralized logistics marketplaces",
        "Better compliance with regulations",
        "Improved traceability",
      ],
    },
    {
      headline: "Education",
      img: BlockchainInEducation,
      tabs: [
        "Decentralized credential management",
        "Digital educational content",
        "Improved transparency and trust",
        "decentralized educational marketplaces",
        "Increased security",
      ],
    },
    {
      headline: "Art",
      img: BlockchainInArt,
      tabs: [
        "Improved provenance",
        "Increased liquidity",
        "New forms of art and revenue streams",
        "Better engagement and fan experience",
        "Improved accessibility",
      ],
    },
  ],
};

export const ABOUT_US_TEXTS = {
  headlineGradient: "About",
  headline: "Us",
  description: [
    "At PeakIT, we are a team of passionate developers who came together with a common vision: to revolutionize the information technology industry and help businesses shape their blockchain future. Since our inception in 2021, we have been dedicated to delivering top-notch solutions that exceed expectations and drive innovation.",

    "Our journey started when we recognized the potential for delivering better-quality products than what we had witnessed in traditional information technology companies. PeakIT was born out of the desire to bring cutting-edge technologies to the forefront and collaborate with businesses to unlock the true potential of blockchain.",

    "With a commitment to excellence, we have successfully executed various projects across the blockchain landscape. You can explore our impressive portfolio on our Projects page, where you'll find examples of our work and the impact we have made for our clients.",

    "At PeakIT, we take pride in our punctuality and never miss a deadline. We are committed to producing high-quality products and services that meet the highest industry standards.  When you work with PeakIT, you gain a dedicated team that invests itself wholeheartedly in your project. We share our ideas, insights, and expertise to ensure that your vision is enriched with our creative input.",
  ],
  tabs: [
    { title: "Completed project:", num: 73 },
    { title: "Completed Mobile Apps:", num: 11 },
    { title: "Competed Web Platforms:", num: 16 },
    { title: "Total Years of Team Experience:", num: 48 },
  ],
  stacks: [
    "Our Tech Stacks:",
    "Node.js / Next.js / JavaScript",
    "React Native / ExpressJS",
    "Solidity / Web3.js",
  ],
};
export const CONTACT_US_TEXTS = {
  headlineGradient: "Contact",
  headline: "Us",
};

export const BLOCKCHAIN_PROJECTS_TEXTS = {
  headline: "Projects",
  length: 4,
  tabs: [
    {
      fullTitle:
        "Advanced Lottery System - Revolutionizing the World of Decentralized Gambling",
      gradientTitle1: "Advanced Lottery System",
      title: " - Revolutionizing the World of",
      title2: "Decentralized Gambling",
      img: lottery,
      date: "22 Dec 2022",
      link: "",
      description:
        "The idea of the project was to enhance the existing PancakeSwap Lottery smart contract and create a dynamic and feature-rich lottery system. With comprehensive improvements and cutting-edge technologies, we introduced a game-changing experience for the decentralized gambling industry.",
      label: "Key Features:",
      content: [
        "Dynamic Functionality: Our team implemented a framework that allows for flexible customization of various aspects of the lottery before each draw. This enables our clients to adapt and optimize the lottery parameters to suit their requirements.",
        "Expanded Number Selection: Unlike traditional lotteries, we upgraded the number of choices from 3 to 6, providing participants with more exciting opportunities to win life-changing prizes.",
        "Native Token Integration: We seamlessly integrated the lottery system with a native token, enhancing the overall user experience and creating a unified ecosystem within the project.",
        "Chainlink V2 Integration: By leveraging the power of Chainlink V2, we ensured transparent and tamper-proof data retrieval, enabling fair and secure lottery outcomes.",
        "Lottery Administrator Panel: We developed a user-friendly and intuitive administrator panel that empowers lottery operators to manage and oversee the entire process effortlessly. From configuring lottery parameters to monitoring ticket sales, the panel provides comprehensive control.",
        "Automated Lottery Management Bot: Our team created a sophisticated bot that autonomously handles the entire lottery process, eliminating the need for human intervention. The bot ensures fairness, efficiency, and reliability while maximizing user satisfaction.",
        "Treasury Contract with Enhanced Ownership Transfer: To further enhance the project's governance and decentralization, we introduced a treasury contract that allows the initial owner to transfer ownership percentages without retaining any influence on the transferred ownership. This ensures a transparent and democratic decision-making process.",
        "Future Injection Functionality: We integrated a future injection functionality that enables injecting funds into future lotteries.",
      ],
      stacks: [
        "Express.js (Backend)",
        "Firebase (Database)",
        "React.js (Frontend)",
        "Web3.js (Smart Contract Interaction)",
        "Solidity (Smart Contract Development)",
        "Ganache (Testing Purpose)",
      ],
    },
    {
      fullTitle:
        "Metanemesis: The Ultimate Video Game Marketplace for NFT Collectibles",
      gradientTitle1: "Marketplace for",
      gradientTitle2: "NFT Collectibles",
      title0: "Metanemesis: The Ultimate Video Game",
      img: metanemesis,
      date: "22 Dec 2022",
      link: "https://metanemesis.io/ ",
      description:
        "Our next project involved the development of Metanemesis, an innovative marketplace dedicated to trading various game attributes as Non-Fungible Tokens (NFTs). Designed to offer a seamless user experience, Metanemesis enables gamers and collectors to buy and sell NFTs securely, while introducing groundbreaking features that elevate the gaming experience to new heights.",

      label: "Key Features:",
      content: [
        "Comprehensive NFT Support: We Metanemesis in a way to support ERC721 and ERC1155 NFT standards, allowing users to trade unique in-game assets. The marketplace initially launched with a focus on ERC1155, allowing users to explore and trade NFTs from three captivating collections: Characters, Spells, and Weapons.",
        "Native Crypto and ERC20 Integration: To facilitate smooth and efficient transactions, we allowed users to purchase NFTs using native cryptocurrencies. Upon completing the purchase, the payment is automatically converted into ERC20 tokens, ensuring compatibility with the broader Ethereum ecosystem.",
        "Whitelisted Contracts: Our team developed a smart contract architecture that whitelists ERC721 and ERC1155 contracts. This feature ensures that only verified and trusted contracts are eligible for trading on the marketplace, enhancing security and protecting users from potential scams.",
        "Web Application: Metanemesis boasts a user-friendly web application that serves as a centralized hub for all trading activities. The application seamlessly integrates with the smart contract, enabling users to effortlessly browse, list, and purchase NFTs. The intuitive interface and responsive design provide a delightful user experience.",
        "Wallet Integration: To streamline the process of connecting with the marketplace and managing NFTs, we implemented Thirdweb, a versatile wallet implementation solution. This integration enables users to securely interact with their wallets, authenticate transactions, and engage in trading activities directly from the Metanemesis platform.",
      ],
      stacks: [
        "Express.js (Backend)",
        "Firebase (Database)",
        "React.js (Frontend)",
        "Web3.js (Smart Contract Interaction)",
        "Thirdweb (Wallet Implementation)",
        "Solidity (Smart Contract Development)",
        "Ganache (Testing Purpose)",
      ],
    },
    {
      fullTitle:
        "SOS AUTO: Roadside Assistance Services",
      gradientTitle1: "Roadside Assistance Services",
      gradientTitle2: "",
      title0: "SOS AUTO:",
      img: sosauto,
      date: "22 Dec 2022",
      link: "https://metanemesis.io/ ",
      description:
        "SOS AUTO  is one-stop shop app for all car troubles, offering instant access to evacuators and a range of roadside assistance services.",

      label: "Key Features:",
      content: [
        "Location Precision: Leveraging advanced geo-mapping, SOS AUTO pinpoints your exact location and nearby assistance, ensuring efficient help arrives swiftly.",
        "Dynamic Pricing Engine: Forget opaque estimates! Our system factors in real-time traffic and distance, delivering accurate quotes that adapt to changing conditions.",
        "Scalable Infrastructure: Built to handle high user volume and service requests, our robust architecture ensures seamless performance even in peak hours.",
        "Secure Payment Gateway: Choose cash on delivery or save your card details with confidence – our secure platform safeguards your financial information.",
        "API Integrations: We seamlessly integrate with tow truck dispatch systems and service providers, streamlining order routing and communication.",
        "Future-Ready Architecture: Built with modularity and adaptability in mind, SOS AUTO is poised to expand its service offerings and coverage area with ease.",
      ],
      stacks: [
        "Node.js",
        "React.Js",
        "Flutter",
      ],
    },
    {
      fullTitle:
        "DecKK: Unlocking Infinite Possibilities with Playful NFT Collectibles",
      title0: "DecKK:",
      gradientTitle1: " Unlocking",
      title: "Infinite Possibilities with ",
      gradientTitle2: "Playful NFT Collectibles",
      img: deck,
      date: "22 Dec 2022",
      link: "",
      description:
        "DecKK is an extraordinary collection of playing cards, serving as the foundational code for endless possibilities, creativity, and imagination. With 52 captivating cards, including four suites and 13 ranked cards in each suit, DecKK offers a unique combination of artistry and gameplay elements. Each card token contains visual cues and a commanding card that adds a special touch to the collection.",
      label: "Objective:",
      content: [
        "Our objective was to develop a collection of playing cards in the form of Non-Fungible Tokens (NFTs) that provided users with a delightful and interactive experience. Every NFT in the collection consists of five distinct cards, showcasing visual cues and incorporating the commanding card to enhance the overall appeal. Additionally, NFT holders from a specific project had the exclusive privilege to double-mint the Deckk* collection using their project NFT numbers, adding an extra layer of value for project participants. For other users, NFTs were generated through a random generation mechanism.",
      ],
      stacks: ["Express.js", "Web3.js", "Solidity", "Ganache"],
    },
    {
      fullTitle:
        "Remex (RMX): Empowering User-Generated Entertainment with Utility Tokens",
      title0: "Remex (RMX):",
      gradientTitle1: " Empowering",
      title: " User-Generated Entertainment with ",
      gradientTitle2: "Utility Tokens",
      img: remex,
      date: "22 Dec 2022",
      link: "https://bscscan.com/token/0x893e258ee221c9c8da50d01108d1ac47cfc3dc9a",
      description:
        "Remex (RMX) is a utility token developed by AGAFOS LLC for its innovative user-generated platform, launched in April 2020. To enhance the entertainment experience, Remex is a fundamental component of their social web app, enabling users to unlock unique features and engage with the platform's offerings.",
      label: "Objective:",
      content: [
        "ERC20 Token Development: We created an ERC20-compliant token, Remex (RMX), to serve as the utility token for the AGAFOS LLC platform. This enabled seamless integration with the entertainment-focused web app, providing users with a unified experience",
        "Customized Vesting Logic: We implemented a customized vesting logic within the smart contracts, specifically tailored for presale investors. By integrating this functionality directly into the smart contracts, we eliminated the need for third-party services, ensuring a secure and transparent process for token vesting",
        "Web3 API Development: Our team built a robust web3 API that facilitated various services for Remex token holders. This included functionalities such as airdrops, transfers, and access to almost every contract feature. The web3 API served as a powerful tool for users to interact with the token and utilize its capabilities within the platform.",
      ],
      stacks: ["Express.js", "Web3.js", "Solidity", "Ganache"],
    },
    {
      fullTitle:
        "Brainnation: Empowering Creativity and Monetization in a Fee-Free Platform",
      title: "Brainnation: Empowering ",
      gradientTitle2: "Creativity and Monetization",
      title2: "in a Fee-Free Platform",
      img: brainnation,
      date: "22 Dec 2022",
      link: "https://bscscan.com/token/0x3be9e3b7bfeab71fe2a8ea2e12f5bb6d5a1a2da1",
      description:
        "Brainnation is a dynamic project that builds upon the success of its predecessor, Remex. This second phase aims to establish a groundbreaking platform that offers users unlimited possibilities for expressing their creativity, earning through gameplay, designing, and monetizing original content, all while providing a fee-free and ad-free experience. Additionally, Brainnation introduces an innovative NFT marketplace, enabling users to explore and engage with the world of non-fungible tokens.",
      label:
        "Our primary objectives for the Brainnation project were as follows:",
      content: [
        "Automated Staking System: We developed a formula and implemented an automated staking system within the smart contracts. This system generated staking profits for users based on various factors such as staked amount, total available tokens, and different types of staking. The contract automatically calculated and credited the staking profits to users, providing a seamless and rewarding staking experience.",
        "Customized Vesting Mechanism: We incorporated a customized vesting mechanism directly into the smart contracts. This allowed for the seamless and secure distribution of tokens, tailored to specific vesting requirements. By customizing the vesting logic within the contract, we ensured transparency and eliminated the need for third-party services.",
        "Web3 API Development: Our team developed a comprehensive Web3 API that provided access to every contract functionality. This API allowed users to interact with the Brainnation platform, perform various actions, and explore the full potential of the ecosystem. Through the Web3 API, users could engage in gameplay, design and monetize content, and seamlessly navigate the NFT marketplace.",
      ],
      stacks: ["Express.js", "Web3.js", "Solidity", "Ganache"],
    },
    {
      fullTitle:
        "WarpJump: Connecting Crypto Whales and Blockchain Innovators through Web3 TV Show",
      title: "WarpJump: Connecting  ",
      gradientTitle2: "Crypto Whales and Blockchain Innovators",
      title2: "  through Web3 TV Show",

      img: warpjump,
      date: "22 Dec 2022",
      link: "https://warpjump.tv/",
      description:
        "WarpJump is an exciting TV show currently under development, dedicated to exploring the world of Web3 and blockchain. It serves as a platform that aims to connect established crypto whales and top blockchain innovators, fostering collaboration and knowledge sharing. As part of the project, our clients desired to establish their own standard token and provide token owners with the ability to actively participate in making crucial decisions related to the show. Additionally, they required a robust fund protection mechanism to ensure the security of the project's funds.",
      label: "Solutions Implemented:",
      content: [
        "Voting System using Snapshot: To facilitate decision-making within the WarpJump project, we implemented the Snapshot platform as the voting system. Only users who owned a certain number of contract tokens issued by the company were eligible to participate in voting initiated by the system. This ensured that the decision-making process was exclusive to token owners, allowing their voices to be heard and influencing the direction of the project.",
        "Fund Protection using Gnosis: To safeguard the project's funds, we utilized Gnosis, a trusted platform for secure wallet management. We implemented a multi-wallet signature approach, where the majority of wallets involved in the decision-making process determined whether a transaction should proceed or not. One of the wallets was owned by token owners, and their voting results, determined through the Snapshot voting system, influenced the response of that specific wallet. This ensured that token owners had a say in financial transactions and provided an added layer of security to protect the project's funds.",
      ],
      stacks: ["Express.js", "Web3.js", "Solidity", "Ganache"],
    },
    {
      fullTitle:
        "The Wine Project: Empowering Georgian Farmers through NFT Wine Sales",
      title0: "The Wine Project: Empowering Georgian ",
      gradientTitle1: "Farmers",
      title: " through ",
      gradientTitle2: "NFT Wine Sales",

      img: wine,
      date: "22 Dec 2022",
      link: "",
      description:
        "The Wine Project is a groundbreaking initiative aimed at providing Georgian farmers with a platform to sell their homemade wine bottles while revolutionizing the wine industry through the integration of non-fungible tokens (NFTs). The project's core concept involves offering customers a unique NFT from a specific series of wine production for each purchased bottle, creating a bridge between traditional winemaking and blockchain technology.",
      label: "Solutions Implemented:",
      content: [
        "Smart Contract Implementation: To realize the vision of The Wine Project, we developed a customized ERC1155 smart contract. This contract enabled each farmer to have their own wine collection, ensuring that their unique offerings could be showcased and sold within the platform. We modified the standard ERC1155 contract to allow farmers the flexibility to upload their new wine collections to IPFS (InterPlanetary File System) and seamlessly integrate them into the contract. This ensured that the platform always showcased the latest wine offerings from the farmers.",
        "Marketplace Website: In addition to the smart contract development, we built a user-friendly marketplace website that served as the central hub for farmers to showcase and sell their wines. The website provided a simple and intuitive interface where farmers could easily list their wine products and create new collections. Customers visiting the website had the opportunity to explore the diverse range of homemade wines available and purchase bottles that piqued their interest. Each purchased bottle automatically rewarded the customer with a unique NFT from the specific wine production series, adding value and collectibility to the customer's experience.",
      ],
      stacks: ["React.js", "Web3.js", "Solidity", "Node.js"],
    },
    {
      fullTitle: "Diverse Smart Contracts for Unique Use Cases",
      gradientTitle1: "Diverse Smart Contracts",
      title: " for Unique Use Cases",
      img: dsc,
      date: "22 Dec 2022",
      link: "",
      description:
        "Our team has successfully developed and deployed several standard smart contracts for unique use cases and innovative ideas. These contracts showcase our versatility and expertise in Solidity, allowing us to create diverse token-based solutions. Here are a few notable examples:",
      label: "Some of small-scale projects:",
      content: [
        "CandyKandy: Ticket-based NFTs on Polygon: CandyKandy is an ERC1155 contract that presents an intriguing concept where each NFT represents a ticket. The contract provides a flexible and scalable solution for managing and distributing tickets on the Polygon network. With this contract, users can participate in events, lotteries, or exclusive experiences by owning these unique NFT tickets.",
        "Is Putin Dead Yet: Unique Message-based Contract: The Is Putin Dead Yet contract introduces an innovative approach where users can leave messages for Putin during the minting process. Once Putin passes away, the cards are flipped, revealing everyone's hidden messages. This contract offers a unique way for individuals to express their opinions and engage with current events through the medium of blockchain and NFTs.",
        "Customized ERC20, ERC1155, ERC721 Contracts: In addition to the specific use cases mentioned above, our team has extensive experience in creating hundreds of ERC20, ERC1155, ERC721, and ERC721a contracts. These contracts have been tailored to meet various requirements and modifications, showcasing our proficiency in developing token standards and customizing them to fit diverse project needs.",
      ],
      stacks: ["React.js", "Web3.js", "Solidity", "Node.js"],
    },
  ],
};

export const BLOCKCHAIN_SUCCESS_STORIES_TEXTS = {
  headline: "Success Stories",
  stories: [
    {
      name: "Darren Dunlap",
      role: "CEO & Founder at Flex.co",
      img: storiesPerson,
      story:
        "The best solution for anyone who wants to work a flexible schedule but still earn a full-time income.",
    },
    {
      name: "Darren Dunlap",
      role: "CEO & Founder at Flex.co",
      img: storiesPerson,
      story:
        "The best solution for anyone who wants to work a flexible schedule but still earn a full-time income.",
    },
    {
      name: "Darren Dunlap",
      role: "CEO & Founder at Flex.co",
      img: storiesPerson,
      story:
        "The best solution for anyone who wants to work a flexible schedule but still earn a full-time income.",
    },
    {
      name: "Darren Dunlap",
      role: "CEO & Founder at Flex.co",
      img: storiesPerson,
      story:
        "The best solution for anyone who wants to work a flexible schedule but still earn a full-time income.",
    },
    {
      name: "Darren Dunlap",
      role: "CEO & Founder at Flex.co",
      img: storiesPerson,
      story:
        "The best solution for anyone who wants to work a flexible schedule but still earn a full-time income.",
    },
  ],
};
export const TEAM_TEXTS = {
  headline: "Team",
  description:
    "t is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution",
  members: [
    {
      name: "member 1",
      img: teamMember,
    },
    {
      name: "member 1 member 1member 1member 1member 1member 1",
      img: teamMember,
    },
    {
      name: "member 2",
      img: teamMember,
    },
    {
      name: "member 3",
      img: teamMember,
    },
    {
      name: "member 4",
      img: teamMember,
    },
    {
      name: "member 5",
      img: teamMember,
    },
    {
      name: "member 6",
      img: teamMember,
    },
  ],
};

export const SMART_CONTRACT_AUDIT_TEXTS = {
  headlineGradient: "Smart Contract",
  headline: "Audit",
  description: [
    "PeakIT provides smart contract audit services for businesses and organizations. We understand the importance of smart contracts in facilitating trustless, secure and automated transactions. Our expert team has extensive experience auditing various types of smart contracts, from simple to complex, ensuring that your smart contracts are secure and free from vulnerabilities.",
    "Audit process includes testing for security, functionality and compliance, giving our partners peace of mind that their smart contracts are safe to use. Our audit services provide an independent and comprehensive assessment of the security and functionality, ensuring that smart contract is thoroughly examined and any issues are identified and addressed promptly.",
  ],
};
export const CONSULTING_TEXTS = {
  headline: "Consulting",
  description: [
    "Before starting any blockchain project, it is essential to have a clear understanding of the technology and its potential use cases for your business. Our team of experienced blockchain consultants has worked on a wide range of projects, from developing decentralized applications and smart contracts, to implementing blockchain-based supply chain management systems. ",
    "In addition, we bring our own ideas and experience to ensure that our partners' initial project will be better. We will work with you to identify the most suitable blockchain platform and use case for your business, and provide guidance on your project's technical and strategic aspects. By working with us, you can be confident that your blockchain project is built on a solid foundation, with a clear roadmap for success ",
  ],
};
