import { useWindowSize } from "../../hooks";
import { DesktopVersion, MobileVersion } from "./atoms";

export const Navigation = ({ setPageDisplayed, setMenu, setWarningModal }) => {
  // detecting if user is using a mobile
  const { mobile, desktop } = useWindowSize();
  return (
    <>
      {desktop && (
        <DesktopVersion
          setMenu={setMenu}
          setPageDisplayed={setPageDisplayed}
          setWarningModal={setWarningModal}
        />
      )}
      {mobile && (
        <MobileVersion
          setMenu={setMenu}
          setPageDisplayed={setPageDisplayed}
          setWarningModal={setWarningModal}
        />
      )}
    </>
  );
};
