import { DesktopVersion, MobileVersion } from "./atoms";

export const Header = ({ setMenu, menu, setPageDisplayed }) => {
  return (
    <>
      <DesktopVersion
        setMenu={setMenu}
        menu={menu}
        setPageDisplayed={setPageDisplayed}
      />
      <MobileVersion setMenu={setMenu} menu={menu} />
    </>
  );
};
