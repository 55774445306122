import "../footer.css";

import facebook from "../../../assets/footer/facebook.webp";
import twitter from "../../../assets/footer/twitter.webp";
import linkedin from "../../../assets/footer/linkedin.webp";

export const MobileVersion = () => {
  return (
    <div className="footer-container-mob non-mob-hidden">
      <span className="footer-line-mob" />
      <img className="footer-left-icon-mob" src={facebook} alt="facebook" />
      <img className="footer-left-icon-mob" src={twitter} alt="twitter" />
      <img className="footer-left-icon-mob" src={linkedin} alt="linkedin" />
      <span className="footer-line-mob" />
      <p className="menu-C-btn-mob">© 2022. PeakIT. All rights reserved.</p>
    </div>
  );
};
