import { useState } from "react";
import { motion } from "framer-motion";

export const MouseAnimation = () => {
  const [animation, setAnimation] = useState(false);

  return (
    <svg
      width="24"
      height="24"
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      onMouseOver={() => setAnimation(true)}
      onMouseLeave={() => setAnimation(false)}
      style={{ cursor: "pointer" }}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 32 32"
    >
      <g data-name="Layer 2">
        <motion.path
          key={animation ? 1 : null}
          initial={{
            pathLength: animation ? 0 : 1,
          }}
          animate={{
            pathLength: 1,
            opacity: 1,
          }}
          transition={{
            duration: 2,
            delay: 0,
            repeat: Infinity,
          }}
          d="M16 2a9.01 9.01 0 00-9 9v10a9 9 0 0018 0V11a9.01 9.01 0 00-9-9zm7 19a7 7 0 01-14 0V11a7 7 0 0114 0z"
        ></motion.path>
        <motion.path
          key={animation ? 2 : 3}
          initial={{
            pathLength: animation ? 0 : 1,
          }}
          animate={{
            pathLength: 1,
            opacity: 1,
          }}
          transition={{
            duration: 2,
            delay: 0,
            repeat: Infinity,
          }}
          d="M16 8a1 1 0 00-1 1v4a1 1 0 002 0V9a1 1 0 00-1-1z"
        ></motion.path>
      </g>
    </svg>
  );
};
