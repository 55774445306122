import { MENU_NAV } from "../../../constants";
import "../navigation.css";

export const MobileVersion = ({
  setPageDisplayed,
  setMenu,
  setWarningModal,
}) => {
  return (
    <div className="menu-container-mob non-mob-hidden">
      <p
        onClick={() => {
          setPageDisplayed({ prev: "none", current: "none" });
          setMenu(false);
        }}
        className="menu-back-btn-mob"
      >
        back to city
      </p>
      <div className="menu-nav-cont-mob">
        {MENU_NAV.map((val, index) => {
          const { name, path } = val;
          return (
            <div
              key={index}
              onClick={() => {
                if (path === "team") {
                  setWarningModal("open");
                } else {
                  setPageDisplayed({ prev: "none", current: path });
                  setMenu(false);
                }
              }}
              className="menu-single-nav-wrapper-mob"
            >
              <div className="menu-single-nav-info-mob">
                <p>{name}</p>
                {/* <img src={arrow} alt="" /> */}
              </div>
              <div>
                {/* <div className="menu-singe-nav-line-blue"></div> */}
                <div className="menu-singe-nav-line-mob"></div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};
