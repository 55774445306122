import React, { Suspense, useEffect, useRef, useState } from "react";
import { Canvas, useFrame, useThree  } from "@react-three/fiber";
import { 
  OrbitControls, 
  Html, 
  useAnimations,  
  useGLTF,
} from "@react-three/drei";
import * as THREE from 'three';

import "../css/background.css";

const Model = ({ onPrimitiveClick }) => {
  const vec = new THREE.Vector3();

  const { scene, animations } = useGLTF("./80sjapan2.gltf", true);
  const { scene: scene2 } = useGLTF("./scene.gltf", true);

  const { actions } = useAnimations(animations, scene);
  const actionsArray = Object.values(actions)

  const markerRef = useRef();

  const [clicked, setClicked] = useState(false);
  const [markerData, setMarkerData] = useState({});

  //----- Animations Play
  useEffect(() => {
    if (actions) {
      actionsArray.map((action) => action.play());
    }
  }, [actions]);

  // useFrame(({ clock }) => {
  //   // Rotate the cube based on elapsed time
  //   markerRef.current.rotation.y = Math.cos(clock.elapsedTime);
  // });
  // useFrame(() => {
  //   // Rotate the entire group for visualization
  //   scene2.rotation.y += 0.02;
  // })

  // useFrame(state => {
  //   if (clicked) { 
  //     // console.log(markerRef.current.position);
  //     state.camera.lookAt(markerRef.current.position)
  //     state.camera.position.lerp(vec.set(4.254774, 3.19184, 4.7726), .01)
  //     state.camera.updateProjectionMatrix()
  //   }
  //   return null;
  // });

  




  

  return (
    <>
      <primitive 
        rotation={[0, 600, 0]}
        object={scene} 
        scale={[0.5, 0.5, 0.5]} 
        position={[0, -8, -6]} 
        // onClick={(event) => console.log(event.point)}
      />
      {/* <primitive
        ref={markerRef}
        rotation={[0, 600, 0]}
        object={scene2}
        scale={[0.1, 0.1, 0.1]} 
        position={[4.254774, 3.19184, -4.7726]} 
        onClick={() => setClicked(!clicked)}
      /> */}
      {/* <directionalLight
        position={[5,5,5]}
        intensity={1}
        color="white"
      /> */}
      <directionalLight
        position={[0,1,0]}
        intensity={1}
        color="white"
      />
    </>
  );
};

export default function BackgroundMob() {
  function Loader() {
    const spinnerStyle = {
      width: '50px',
      height: '50px',
      margin: '0 auto',
      border: '5px solid #f3f3f3',
      borderRadius: '50%',
      borderTopColor: '#508EF2',
      animation: 'spin 1.5s linear infinite',
    };

    return (
      <Html>
        <div className="loader-container">
          <div className="spinner" style={spinnerStyle} />
        </div>
      </Html>
    );
  }

 

  return (
    <Canvas camera={{ position: [-0.2, 2, 6], fov: 60, }} >
      <Suspense fallback={<Loader />}>
        <Model />
        <OrbitControls
          enableDamping
          dampingFactor={0.05}
          minAzimuthAngle={-60 * (Math.PI / 180)}
          maxAzimuthAngle={60 * (Math.PI / 180)}
          minPolarAngle={30 * (Math.PI / 180)}
          maxPolarAngle={90 * (Math.PI / 180)}
          maxDistance={10}
          enableZoom={false}
        />
      </Suspense>
    </Canvas>
  );
}