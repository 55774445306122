import { DesktopVersion, MobileVersion } from "./atoms";

export const Footer = () => {
  return (
    <>
      <DesktopVersion />
      <MobileVersion />
    </>
  );
};
