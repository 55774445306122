import { SERVICES_TEXTS, ROUTES } from "../../../constants";
import "../services.css";
import { ArrowAnimation } from "../../../animation";

import scrollArrow from "../../../assets/common/scrollDown.webp";

export const DesktopVersion = ({
  setPageDisplayed,
  pageDisplayed,
  setNum,
  setWarningModal,
}) => {
  return (
    <div
      className={
        pageDisplayed.current === ROUTES.services &&
        pageDisplayed.prev !== ROUTES.crypto
          ? "services-container mob-hidden appear-up"
          : pageDisplayed.current === ROUTES.services &&
            pageDisplayed.prev === ROUTES.crypto
          ? "services-container mob-hidden appear-down"
          : pageDisplayed.prev === ROUTES.services
          ? "services-container mob-hidden exit-up"
          : "services-container mob-hidden hidden"
      }
    >
      <h3 className="page-headline">{SERVICES_TEXTS.headline}</h3>
      <div className="services-content-wrapper">
        <div className="services-content-left">
          <p>{SERVICES_TEXTS.leftName}</p>
          <div className="services-content-left-tabs">
            {SERVICES_TEXTS.left.map((val, index) => {
              return (
                <p
                  onClick={() =>
                    setPageDisplayed({ prev: "none", current: val })
                  }
                  key={index}
                >
                  {val}
                </p>
              );
            })}
          </div>
        </div>
        <div className="services-content-right">
          <p>{SERVICES_TEXTS.rightName}</p>
          <div className="services-content-right-tabs">
            {SERVICES_TEXTS.right.map((val, index) => {
              return (
                <p
                  onClick={() => {
                    if (
                      val === SERVICES_TEXTS.right[0] ||
                      val === SERVICES_TEXTS.right[1]
                    )
                      return setWarningModal("open");
                    setPageDisplayed(() => {
                      return { prev: "none", current: val };
                    });
                  }}
                  key={index}
                >
                  {val}
                </p>
              );
            })}
          </div>
        </div>
      </div>
      <div className="services-scroll-tip-wrapper">
        <p>scroll down</p>
        <div className="services-scroll-tip-arrow">
          <div className="services-scroll-tip-left-line"></div>
          <ArrowAnimation
            name="scroll-down-btn-services"
            setState={setNum}
            value={3}
            alt="scroll down"
          />
          <div className="services-scroll-tip-right-line"></div>
        </div>
      </div>
    </div>
  );
};
