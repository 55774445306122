import "../../css/blockchain css/smartContractAudit.css";
import { ROUTES, SMART_CONTRACT_AUDIT_TEXTS } from "../../constants";

// images
import audit from "../../assets/audit/audit.webp";

const DesktopVersion = ({ pageDisplayed }) => {
  return (
    <div
      className={
        pageDisplayed.prev === ROUTES.defi &&
        pageDisplayed.current === ROUTES.smartContractAudit
          ? "smart-contract-audit-container mob-hidden appear-up"
          : pageDisplayed.current === ROUTES.defi &&
            pageDisplayed.prev === ROUTES.smartContractAudit
          ? "smart-contract-audit-container mob-hidden exit-down"
          : pageDisplayed.current === ROUTES.smartContractAudit2 &&
            pageDisplayed.prev === ROUTES.smartContractAudit
          ? "smart-contract-audit-container mob-hidden exit-up"
          : pageDisplayed.current === ROUTES.smartContractAudit &&
            pageDisplayed.prev === ROUTES.smartContractAudit2
          ? "smart-contract-audit-container mob-hidden appear-down"
          : pageDisplayed.current === ROUTES.smartContractAudit &&
            pageDisplayed.prev === "none"
          ? "smart-contract-audit-container mob-hidden"
          : "smart-contract-audit-container mob-hidden hidden"
      }
    >
      <div className="gradient-headline-wrapper">
        <h3 className="page-headline gradient-headline">
          {SMART_CONTRACT_AUDIT_TEXTS.headlineGradient}
        </h3>
        <h3 className="page-headline">{SMART_CONTRACT_AUDIT_TEXTS.headline}</h3>
      </div>
      <div className="smart-contract-audit-content-wrapper">
        <img src={audit} alt="" />
        <div>
          {SMART_CONTRACT_AUDIT_TEXTS.description.map((val, index) => {
            return (
              <p className="smart-contract-audit-description" key={index}>
                {val}
              </p>
            );
          })}
        </div>
      </div>
    </div>
  );
};
const MobileVersion = ({ pageDisplayed }) => {
  return (
    <div
      className={
        pageDisplayed.prev === ROUTES.defi &&
        pageDisplayed.current === ROUTES.smartContractAudit
          ? "smart-contract-audit-container-mob non-mob-hidden appear-up"
          : pageDisplayed.current === ROUTES.defi &&
            pageDisplayed.prev === ROUTES.smartContractAudit
          ? "smart-contract-audit-container-mob non-mob-hidden exit-down"
          : pageDisplayed.current === ROUTES.smartContractAudit2 &&
            pageDisplayed.prev === ROUTES.smartContractAudit
          ? "smart-contract-audit-container-mob non-mob-hidden exit-up"
          : pageDisplayed.current === ROUTES.smartContractAudit &&
            pageDisplayed.prev === ROUTES.smartContractAudit2
          ? "smart-contract-audit-container-mob non-mob-hidden appear-down"
          : pageDisplayed.current === ROUTES.smartContractAudit &&
            pageDisplayed.prev === "none"
          ? "smart-contract-audit-container-mob non-mob-hidden"
          : "smart-contract-audit-container-mob non-mob-hidden hidden"
      }
    >
      <div className="gradient-headline-wrapper-mob">
        <h3 className="page-headline gradient-headline">
          {SMART_CONTRACT_AUDIT_TEXTS.headlineGradient}
        </h3>
        <h3 className="page-headline">{SMART_CONTRACT_AUDIT_TEXTS.headline}</h3>
      </div>
      <div className="smart-contract-audit-content-wrapper-mob">
        <img src={audit} alt="" />
        <div>
          {SMART_CONTRACT_AUDIT_TEXTS.description.map((val, index) => {
            return (
              <p className="smart-contract-audit-description" key={index}>
                {val}
              </p>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export const SmartContractAudit = ({ pageDisplayed }) => {
  return (
    <>
      <DesktopVersion pageDisplayed={pageDisplayed} />
      <MobileVersion pageDisplayed={pageDisplayed} />
    </>
  );
};
