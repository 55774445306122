import { BLOCKCHAIN_PROJECTS_TEXTS, ROUTES } from "../../../constants";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Parallax } from "swiper";

// css
import "../projects.css";

// images
import Arrow from "../../../assets/common/ArrowRight.webp";
import ArrowLeft from "../../../assets/common/ArrowLeft.webp";
import { ProjectViewDesktop } from "./projectViewDesktop";

export const DesktopVersion = ({
  setPagination,
  prevRef,
  nextRef,
  pageDisplayed,
  projectToView,
  setProjectToView,
}) => {
  return (
    <div
      className={
        pageDisplayed.prev === ROUTES.ar &&
        pageDisplayed.current === ROUTES.bProjects
          ? "blockchain-projects-container mob-hidden appear-up"
          : pageDisplayed.current === ROUTES.ar &&
            pageDisplayed.prev === ROUTES.bProjects
          ? "blockchain-projects-container mob-hidden exit-down"
          : "blockchain-projects-container mob-hidden hidden"
      }
    >
      <div className="blockchain-projects-header">
        <h3 className="page-headline">{BLOCKCHAIN_PROJECTS_TEXTS.headline}</h3>
      </div>
      <div className="blockchain-projects-arrow-wrapper">
        <img
          ref={prevRef}
          className="blockchain-projects-arrow"
          src={ArrowLeft}
          alt="left arrow"
        />
        <img
          ref={nextRef}
          className="blockchain-projects-arrow "
          src={Arrow}
          alt="right arrow"
        />
      </div>
      <div className="blockchain-projects-content-wrapper">
        <Swiper
          modules={[Navigation, Pagination, Parallax]}
          pagination={{
            clickable: true,
            type: "custom",
            renderCustom: (swiper, children) => setPagination(children),
          }}
          onInit={(swiper) => {
            swiper.params.navigation.prevEl = prevRef.current;
            swiper.params.navigation.nextEl = nextRef.current;
            swiper.navigation.init();
            swiper.navigation.update();
          }}
          spaceBetween={100}
          slidesPerView={3}
          scrollbar={{ draggable: true }}
          observer={true}
          observeParents={true}
          parallax={true}
        >
          {BLOCKCHAIN_PROJECTS_TEXTS.tabs.map((val, index) => {
            return (
              <SwiperSlide key={index}>
                <div
                  className="blockchain-projects-single-slide"
                  onClick={() => {
                    setProjectToView({ status: "open", project: val });
                  }}
                >
                  <img src={val.img} alt="slide img" />
                  <div className="blockchain-projects-single-slide-desc">
                    <p className="blockchain-project-title">{val.fullTitle}</p>
                    {/* <div>
                      <p>{val.date}</p>
                    </div> */}
                  </div>
                </div>
              </SwiperSlide>
            );
          })}
        </Swiper>
      </div>
    </div>
  );
};
